import React, { useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Constants } from "../../../../app-lib/constants";
import { AppState } from "../../../store/reducers";

import Logo from '../../assets/img/logo.svg';

export default ({toggled}: any) => {
  const user = useSelector((state: AppState) => state.user);
  const plan = useMemo(() => user?.user?.plan, [user]);

  return (
    <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${toggled ? 'toggled' : ''}`}>

      {/*  Sidebar - Brand --> */}
      <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="">
        <div className="sidebar-brand-icon rotate-n-15">
          <img src={Logo} />
        </div>
        <div className="sidebar-brand-text mx-3">{Constants.APP.NAME}</div>
      </Link>
      {/*  Divider --> */}
      <hr className="sidebar-divider" />

      {/*
      <li className="nav-item active">
        <Link className="nav-link" to="">
          <i className="fas fa-fw fa-home"></i>
          <span>Home</span>
        </Link>
      </li>
      <hr className="sidebar-divider my-0" />
      */}


      <div className="sidebar-heading">
        Situazione
      </div>

      <li className="nav-item">
        <Link className="nav-link" to="/situation">
          <i className="fas fa-fw fa-utensils"></i>
          <span>Situazione</span>
        </Link>
      </li>
      {/*  Divider --> */}
      <hr className="sidebar-divider" />

      {(plan && plan.menu == true) && (
        <>
          <div className="sidebar-heading">
            Menu
          </div>

          <li className="nav-item">
            <Link className="nav-link" to="/menus">
              <i className="fas fa-fw fa-utensils"></i>
              <span>Gestione menu</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/dishes">
              <i className="fas fa-fw fa-utensils"></i>
              <span>Tutti i piatti</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/ingredients">
              <i className="fas fa-fw fa-utensils"></i>
              <span>Tutti gli ingredienti</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/qrcode">
              <i className="fas fa-fw fa-qrcode"></i>
              <span>Il mio QR Code</span>
            </Link>
          </li>
          {/*  Divider --> */}
          <hr className="sidebar-divider" />
        </>
      )}

      {(plan && plan.reservations == true) && (
        <>
          {/*  Heading --> */}
          <div className="sidebar-heading">
            Prenotazioni
          </div>

          <li className="nav-item">
            <Link className="nav-link" to="/reservations">
              <i className="fas fa-fw fa-utensils"></i>
              <span>Lista prenotazioni</span>
            </Link>
          </li>

          {/*  Divider --> */}
          <hr className="sidebar-divider" />
        </>
      )}

      <div className="sidebar-heading">
        Clienti
      </div>

      <li className="nav-item">
        <Link className="nav-link" to="/customers">
          <i className="fas fa-fw fa-utensils"></i>
          <span>Lista clienti</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="/survey-satisfaction">
          <i className="fas fa-fw fa-utensils"></i>
          <span>Sondaggio soddisfazione</span>
        </Link>
      </li>

      {/*  Divider --> */}
      <hr className="sidebar-divider" />

      {/*  Heading --> */}
      <div className="sidebar-heading">
        Impostazioni
      </div>

      <li className="nav-item">
        <Link className="nav-link" to="/settings">
          <i className="fas fa-fw fa-utensils"></i>
          <span>Ristorante</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="/rooms">
          <i className="fas fa-fw fa-utensils"></i>
          <span>Sale e tavoli</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="/opening-times">
          <i className="fas fa-fw fa-utensils"></i>
          <span>Orari di apertura</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/opening-times-special">
          <i className="fas fa-fw fa-utensils"></i>
          <span>Orari di apertura speciali</span>
        </Link>
      </li>

      {/*  Heading --> */}
      {/* <div className="sidebar-heading">
        Ordini
      </div>

      <li className="nav-item">
        <Link className="nav-link" to="">
          <i className="fas fa-fw fa-book-open"></i>
          <span>Visualizza ordini</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="">
          <i className="fas fa-fw fa-pen-square"></i>
          <span>Nuovo ordine</span>
        </Link>
      </li>

      <hr className="sidebar-divider" /> */}

      {/*  Heading --> */}
      {/* <div className="sidebar-heading">
        Utilità
      </div>

      <li className="nav-item">
        <Link className="nav-link" to="">
          <i className="fas fa-fw fa-eye"></i>
          <span>Anteprima</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="">
          <i className="fas fa-fw fa-life-ring"></i>
          <span>Assistenza</span>
        </Link>
      </li> */}

      {/*  Divider --> */}
      <hr className="sidebar-divider d-none d-md-block" />

      {/*  Sidebar Toggler (Sidebar) --> */}
      {/* <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" onClick={() => setToggled(!toggled)} value="toggle" id="sidebarToggle"></button>
      </div> */}

    </ul>
  );
}
