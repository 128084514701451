import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { DateTime, Info } from 'luxon';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from 'chart.js';
import { Line, Pie, getElementAtEvent, Bar } from 'react-chartjs-2';

import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import sdk from '../../../app-lib/sdk/sdk.service';
import { Utils } from '../../../lib/utils/core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const today = (new Date()).toISOString().split('T')[0];
const oneDayInMs = 24 * 60 * 60 * 1000;
// const yesterday = (new Date(Date.now() - oneDayInMs)).toISOString().split('T')[0];

const Counter = ({number, duration = 2}: {number: number, duration?: number}) => {
  const [count, setCount] = useState("0");

  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = parseFloat(String(number).substring(0,3))
    // if zero, return

    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = duration;
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + String(number).substring(3))
      if (start >= end) {
        setCount(String(number))
        clearInterval(timer)
      }
    }, incrementTime);

  }, [number, duration]);

  return (
    <span className="counter">
      {count}
    </span>
  );
}

export default function Analytics(props: any): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [reservations, setReservations] = useState<ResponseResults.Reservation[]>([]);
  const [customers, setCustomers] = useState<ResponseResults.Customer[]>([]);
  // const [times, setTimes] = useState<ResponseResults.Availabilities[]>();
  const [errors, setErrors] = useState<string[]>([]);

  const chartBestCustomersRef = useRef();

  const todayReservation = useMemo(() => {
    return reservations.filter(r => r.date === today).length
  }, [reservations]);
  const todayReservationToConfirm = useMemo(() => {
    return reservations.filter(r => r.date === today && r.state === 'pending').length
  }, [reservations]);
  const sevenDaysAgoReservation = useMemo(() => {
    const sevenDaysAgo: Date = new Date(Date.now() - 7 * oneDayInMs);

    return reservations.filter(r => (new Date(r.date)) >= sevenDaysAgo && (new Date(r.date)) < new Date()).length;
  }, [reservations]);
  const avgReservation = useMemo(() => {
    const pastReservations = reservations.filter(r => r.date < today)
    const dates = reservations
      .map(r => r.date)
      .filter(d => d < today)
      .filter((v, i, a) => a.indexOf(v) === i); // get dates without duplicates

    return pastReservations.length / dates.length || 0;
  }, [reservations]);

  const customersCount = useMemo(() => {
    return customers.length;
  }, [customers]);

  const savingLastMonth = useMemo(() => {
    const savingForReservation = 1.5;

    const today = DateTime.local();
    const startOfLastMonth = today.minus({ months: 1 }).startOf('month');
    const endOfLastMonth = today.minus({ months: 1 }).endOf('month');

    const reservationOfLastMonth = reservations.filter(r => {
      const reservationDate = DateTime.fromISO(r.date);

      return reservationDate >= startOfLastMonth && reservationDate <= endOfLastMonth;
    });

    return reservationOfLastMonth.length * savingForReservation;
  }, [reservations]);

  const loadData = useCallback(() => {
    setLoading(true);
    Promise.all([
      sdk.getReservations().then(res => {
        setReservations(res.results.filter(r => r.state !== 'cancelled'));
      }),

      sdk.getCustomers().then(res => {
        setCustomers(res.results);
      }),

      // sdk.getAvailabilities(today).then((a) => {
      //   setTimes(a.results)
      // }),
    ])
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => {
    loadData()
  }, []);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
    },
  };

  const months = Info.months('long');
  const weekdays = Info.weekdays('long');

  const chartCustomersData = useMemo(() => {
    return {
      labels: months,
      datasets: [
        {
          label: '',
          data: months.map((m, i) => {
            const month = i + 1;

            return customers.filter(c => {
              const year = DateTime.local().year;

              const date = DateTime.fromISO(c.created_at as string);
              const startOfMonth = DateTime.fromObject({ year, month, day: 1 });
              const endOfMonth = startOfMonth.endOf('month');

              return date >= startOfMonth && date <= endOfMonth;
            }).length
          }),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }
  }, [customers]);

  const chartReservationsMonthlyData = useMemo(() => {
    return {
      labels: months,
      datasets: [
        {
          label: '',
          data: months.map((m, i) => {
            const month = i + 1;

            return reservations.filter(c => {
              const year = DateTime.local().year;

              const date = DateTime.fromISO(c.date as string);
              const startOfMonth = DateTime.fromObject({ year, month, day: 1 });
              const endOfMonth = startOfMonth.endOf('month');

              return date >= startOfMonth && date <= endOfMonth;
            }).length
          }),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }
  }, [reservations]);

  const chartReservationsWeeklyData = useMemo(() => {
    return {
      labels: weekdays,
      datasets: [
        {
          label: '',
          data: weekdays.map((w, i) => {
            const weekday = i+1;

            return reservations.filter(reservation => {
              const date = DateTime.fromISO(reservation.date);
              return date.weekday === DateTime.fromObject({ weekday }).weekday;
            }).length;
          }),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }
  }, [reservations]);

  const bestCustomers = useMemo(() => customers
    .map(c => ({...c, reservations: c.reservations?.filter(r => r.state !== 'cancelled')}))
    .sort((a,b) => (b.reservations as any).length - (a.reservations as any).length)
    .filter((c,i) => i < 10),
    [customers]
  );

  const chartBestCustomersData = useMemo(() => {
    return {
      labels: bestCustomers.map(c => `${c.first_name} ${c.last_name}`),
      datasets: [
        {
          label: '',
          data: bestCustomers.map(c => c.reservations?.length),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: bestCustomers.map(c => '#' + Math.floor(Math.random()*16777215).toString(16)),
        },
      ],
    }
  }, [customers]);

  return (
    <div id="content">
      {/*  Begin Page Content --> */}
      <div className="container-fluid">
        {errors && errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {errors.map((error, idx) => (
              <p key={'error-' + idx}>{error}</p>
            ))}
          </div>
        )}

        {/*  Page Heading --> */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
          {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
        </div>

        {/*  Content Row --> */}
        <div className="row analytics-card">

          {/*  Pending Requests Card Example --> */}
          <div className="col-xl-2 col-6 mb-4">
            <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="mr-2">
                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1" style={{minHeight: '50px'}}>Prenotazioni da confermare oggi</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800 value">
                      <Counter number={Utils.roundDigits(todayReservationToConfirm)} />
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/*  Earnings (Monthly) Card Example --> */}
          <div className="col-xl-2 col-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1" style={{minHeight: '50px'}}>Prenotazioni di oggi</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800 value">
                      <Counter number={Utils.roundDigits(todayReservation)} />
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/*  Earnings (Monthly) Card Example --> */}
          <div className="col-xl-2 col-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{minHeight: '50px'}}>Prenotazioni negli ultimi 7gg</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800 value">
                      <Counter number={Utils.roundDigits(sevenDaysAgoReservation)} />
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/*  Earnings (Monthly) Card Example --> */}
          <div className="col-xl-2 col-6 mb-4">
            <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1" style={{minHeight: '50px'}}>Media prenotazioni giornaliere</div>
                      <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800 value">
                        <Counter number={Utils.roundDigits(avgReservation)} />
                      </div>
                    {/* <div className="row no-gutters align-items-center"> */}
                      {/* <div className="col">
                        <div className="progress progress-sm mr-2">
                          <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                      </div> */}
                    {/* </div> */}
                  </div>
                  {/* <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/*  Earnings (Monthly) Card Example --> */}
          <div className="col-xl-2 col-6 mb-4">
            <div className="card border-left-danger shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="mr-2">
                    <div className="text-xs font-weight-bold text-danger text-uppercase mb-1" style={{minHeight: '50px'}}>Numero clienti</div>
                    <div className="row no-gutters align-items-center">
                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800 value">
                          <Counter number={customersCount} />
                        </div>
                      {/* <div className="col">
                        <div className="progress progress-sm mr-2">
                          <div className="progress-bar bg-danger" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/*  Earnings (Monthly) Card Example --> */}
          <div className="col-xl-2 col-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{minHeight: '50px'}}>Risparmio del mese scorso<br /><small>rispetto agli aggregatori</small></div>
                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800 value">
                          € <Counter number={Utils.roundDigits(savingLastMonth)} />
                        </div>
                    {/* <div className="row no-gutters align-items-center"> */}
                      {/* <div className="col">
                        <div className="progress progress-sm mr-2">
                          <div className="progress-bar bg-success" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                      </div> */}
                    {/* </div> */}
                  </div>
                  {/* <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

        </div>

        {/*  Content Row --> */}
        <div className="row">

          {/*  Area Chart --> */}
          <div className="col-md-5">
            <div className="card shadow mb-4">
              {/*  Card Header - Dropdown --> */}
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Prenotazioni effettuate (per mese)</h6>
                {/* <div className="dropdown no-arrow">
                  <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                    <div className="dropdown-header">Dropdown Header:</div>
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">Something else here</a>
                  </div>
                </div> */}
              </div>
              {/*  Card Body --> */}
              <div className="card-body">
                <div className="chart-area">
                  {/* <canvas id="myAreaChart"></canvas> */}
                  <Line options={chartOptions} data={chartReservationsMonthlyData} />
                </div>
              </div>
            </div>
          </div>

          {/*  Area Chart --> */}
          <div className="col-md-5">
            <div className="card shadow mb-4">
              {/*  Card Header - Dropdown --> */}
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Nuovi clienti</h6>
                {/* <div className="dropdown no-arrow">
                  <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                    <div className="dropdown-header">Dropdown Header:</div>
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">Something else here</a>
                  </div>
                </div> */}
              </div>
              {/*  Card Body --> */}
              <div className="card-body">
                <div className="chart-area">
                  {/* <canvas id="myAreaChart"></canvas> */}
                  <Line options={chartOptions} data={chartCustomersData} />
                </div>
              </div>
            </div>
          </div>

          {/*  Pie Chart --> */}
          <div className="col-md-2">
            <div className="card shadow mb-4">
              {/*  Card Header - Dropdown --> */}
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Migliori clienti</h6>
                {/* <div className="dropdown no-arrow">
                  <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                    <div className="dropdown-header">Dropdown Header:</div>
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">Something else here</a>
                  </div>
                </div> */}
              </div>
              {/*  Card Body --> */}
              <div className="card-body">
                <div className="chart-area pt-4 pb-2">
                  <Pie ref={chartBestCustomersRef} options={chartOptions} data={chartBestCustomersData} onClick={(e: any) => {
                    console.log(bestCustomers[getElementAtEvent(chartBestCustomersRef.current as any, e)[0].index])
                  }} />
                  {/* <canvas id="myPieChart"></canvas> */}
                </div>
                {/* <div className="mt-4 text-center small">
                  <span className="mr-2">
                    <i className="fas fa-circle text-primary"></i> Direct
                  </span>
                  <span className="mr-2">
                    <i className="fas fa-circle text-success"></i> Social
                  </span>
                  <span className="mr-2">
                    <i className="fas fa-circle text-info"></i> Referral
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/*  Area Chart --> */}
        <div className="col-md-5">
          <div className="card shadow mb-4">
            {/*  Card Header - Dropdown --> */}
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">Prenotazioni effettuate (per settimana)</h6>
              {/* <div className="dropdown no-arrow">
                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                  <div className="dropdown-header">Dropdown Header:</div>
                  <a className="dropdown-item" href="#">Action</a>
                  <a className="dropdown-item" href="#">Another action</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">Something else here</a>
                </div>
              </div> */}
            </div>
            {/*  Card Body --> */}
            <div className="card-body">
              <div className="chart-area">
                {/* <canvas id="myAreaChart"></canvas> */}
                <Bar options={chartOptions} data={chartReservationsWeeklyData} />
              </div>
            </div>
          </div>
        </div>

        <div className='d-none'>
        {/*  Content Row --> */}
        <div className="row">

          {/*  Content Column --> */}
          <div className="col-lg-6 mb-4">

            {/*  Project Card Example --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Projects</h6>
              </div>
              <div className="card-body">
                <h4 className="small font-weight-bold">Server Migration <span className="float-right">20%</span></h4>
                <div className="progress mb-4">
                  <div className="progress-bar bg-danger" role="progressbar" style={{width: '20%'}} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
                <h4 className="small font-weight-bold">Sales Tracking <span className="float-right">40%</span></h4>
                <div className="progress mb-4">
                  <div className="progress-bar bg-warning" role="progressbar" style={{width: '40%'}} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
                <h4 className="small font-weight-bold">Customer Database <span className="float-right">60%</span></h4>
                <div className="progress mb-4">
                  <div className="progress-bar" role="progressbar" style={{width: '60%'}} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
                <h4 className="small font-weight-bold">Payout Details <span className="float-right">80%</span></h4>
                <div className="progress mb-4">
                  <div className="progress-bar bg-info" role="progressbar" style={{width: '80%'}} aria-valuenow={80} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
                <h4 className="small font-weight-bold">Account Setup <span className="float-right">Complete!</span></h4>
                <div className="progress">
                  <div className="progress-bar bg-success" role="progressbar" style={{width: '100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-6 mb-4">

            {/*  Illustrations --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Illustrations</h6>
              </div>
              <div className="card-body">
                <div className="text-center">
                  <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: '25rem'}} src="img/undraw_posting_photo.svg" alt="" />
                </div>
                <p>Add some quality, svg illustrations to your project courtesy of <a target="_blank" rel="nofollow" href="https://undraw.co/">unDraw</a>, a constantly updated collection of beautiful svg images that you can use completely free and without attribution!</p>
                <a target="_blank" rel="nofollow" href="https://undraw.co/">Browse Illustrations on unDraw &rarr;</a>
              </div>
            </div>

            {/*  Approach --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Development Approach</h6>
              </div>
              <div className="card-body">
                <p>SB Admin 2 makes extensive use of Bootstrap 4 utility classes in order to reduce CSS bloat and poor page performance. Custom CSS classes are used to create custom components and custom utility classes.</p>
                <p className="mb-0">Before working with this theme, you should become familiar with the Bootstrap framework, especially the utility classes.</p>
              </div>
            </div>

          </div>
        </div>
        </div>
      </div>
      {/*  /.container-fluid --> */}

    </div>
  );
}
