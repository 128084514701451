import React, { useEffect, useMemo, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import QRCodeReact from 'qrcode.react';
import QRCode from 'qrcode';

import { Utils } from '../../../lib/utils/core';
import { Modal } from '../components/molecules/modal';
import { Document, Image, Page, PDFDownloadLink, Text, View } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/reducers';

const QRGenerator = (props: any) => {
  const { valueString, documentId } = props;

  return (
    <div>
      <QRCodeReact
        id={documentId}
        value={valueString}
        size={128}
        bgColor="#ffffff"
        fgColor="#000000"
        level="H"
        includeMargin={true}
      />
    </div>
  )
}

interface Tmp {
  url: string,
  label: string
}
const QRCodePDFDocument = ({data}: { data: Tmp[] }) => {
  return (
    <Document>

      {data.map(({url, label}, idx) => (
        <Page key={`page_${idx}`} size="B8">
          <View>
            <Image src={url} />
          </View>
          <View>
            <Text>{label}</Text>
          </View>
        </Page>
      ))}
    </Document>
  )
}

const QRCodeGenerator = ({data}: { data: Tmp[] }) => {
  const downloadName = 'qrcodes';
  const [newData, setNewData] = useState<Tmp[]>();

  useEffect(() => {
    const dd = data.map(async (d) => {
      const url = await QRCode.toDataURL(d.url, { type: "image/png" })

      return {
        ...d,
        url,
      }
    });
    Promise.all(dd).then(r => setNewData(r))
  }, [data])

  return (
    <div>
      {newData && (
        <PDFDownloadLink
          document={<QRCodePDFDocument data={newData} />}
          fileName={`${downloadName}.pdf`}
          className="btn btn-primary"
        >
          Scarica in PDF per la stampa
        </PDFDownloadLink>
      )}
    </div>
  )

}

export default function Customers(props: any): JSX.Element {
  const user = useSelector((state: AppState) => state.user);
  const plan = useMemo(() => user?.user?.plan, [user]);

  console.log(plan, plan ? `https://${plan.frontend_domain}/menu` : '');

  const [openModal, setOpenModal] = useState<false|'single'|'tables'>();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<ResponseResults.Customer[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">QR Code</h1>

      <div className="text-center">
        {plan && plan.frontend_domain && (
          <>
            <QRGenerator valueString={`https://${plan.frontend_domain}/menu`} />
            <QRCodeGenerator
              data={[
                {
                  url: `https://${plan.frontend_domain}/menu`,
                  label: ''
                },
              ]}
            />
          </>
        )}
      </div>

      {/* <button className="btn btn-primary" onClick={() => setOpenModal('single')}>Scarica il QR Code del menù</button>
      <button className="btn btn-secondary" onClick={() => setOpenModal('tables')}>Scarica un QR Code per ogni tavolo</button>

      <QRCodeGenerator
        data={[
          {
            url: 'https://alpomodoro.co.uk/menu?t=1',
            label: 't1'
          },
          {
            url: 'https://alpomodoro.co.uk/menu?t=2',
            label: 't2'
          },
        ]}
      /> */}

      {/* non usato */}
      {/* {openModal && (
        <Modal
          onClose={() => setOpenModal(false)}
        >
          <>
            {openModal === 'single' && (
              <QRGenerator value="https://alpomodoro.co.uk/menu" />
            )}
            {openModal === 'tables' && (
              <>
                <QRCodeSVG value="https://alpomodoro.co.uk/menu" />
                <QRCodeSVG value="https://alpomodoro.co.uk/menu" />
                <QRCodeSVG value="https://alpomodoro.co.uk/menu" />
                <QRCodeSVG value="https://alpomodoro.co.uk/menu" />
                <QRCodeSVG value="https://alpomodoro.co.uk/menu" />
              </>
            )}
          </>
        </Modal>
      )} */}
    </div>
  );
}
