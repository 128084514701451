import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

interface AccordionProps {
  title: ReactNode;
  children: string | ReactElement;
  color?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  color,
}: AccordionProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    const toOpen = !open;
    setOpen(toOpen);
  }

  return (
    <div className='p-2'>
      <div className="float-left">
        <span role='button' onClick={handleClick} className='p-2'>
          {'>'}
        </span>
      </div>

      <div className="">
        {title}
        {/* <h3 className="font-bold my-24 text-18">{title}</h3> */}
        <div
          className='ml-4'
          style={{ display: open ? 'block' : 'none' }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
