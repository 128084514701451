import React, { useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import List, { ListArgs } from '../components/organisms/list.component';
import { Utils } from '../../../lib/utils/core';

export default function Dishes(props: any): JSX.Element {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [dishes, setDishes] = useState<ResponseResults.Dish[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const [showOnlyRelated, setShowOnlyRelated] = useState(false);

  useEffect(() => {
    setLoading(true);
    sdk.getDishes()
      .then(res => setDishes(res.results))
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, []);

  const ListDish = List as React.FC<ListArgs<ResponseResults.Dish>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Dishes</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            <label>
              <input type="checkbox" className="mr-2" checked={showOnlyRelated} onChange={() => { setShowOnlyRelated(!showOnlyRelated) }} />
              Mostra solo i piatti non associati ad alcun menu
            </label>

            <ListDish
              model={dishes.filter(d => {
                if (showOnlyRelated) {
                  return !(d.menu_sections && d.menu_sections.length > 0)
                }
                return true;
              })}
              title=""
              addPlaceholder="Add dish"
              generateListLink={(model: any) => {
                return `/dishes/${model.id}`;
              }}
              onCreateNew={(value: string) => {
                setLoading(true);
                sdk.createDish({
                  name: value,
                  price_default: 0,
                })
                  .then((dish => history.push('/dishes/' + dish.results.id)))
                  .catch(e => setErrors(Utils.formatErrors(e)))
              }}
            />
          </div>
        )
      }
    </div>
  );
}
