import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import { UpdateForm } from '../components/organisms/updateForm.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { Utils } from '../../../lib/utils/core';

export default function Location(props: any): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState<ResponseResults.Location>();
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getLocation(1)
      .then(res => {
        setLocation(res.results);
        console.log(res.results)
      })
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, []);

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Locale</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            <div>
              <UpdateForm
                model={location}
                loading={loading}
                fields={[
                  {
                    name: 'name',
                    type: 'text',
                    placeholder: 'Nome del locale',
                  },
                  {
                    name: 'address',
                    type: 'text',
                    placeholder: 'Indirizzo',
                  },
                  {
                    name: 'latitude',
                    type: 'text',
                    placeholder: 'Latitudine',
                  },
                  {
                    name: 'longitude',
                    type: 'text',
                    placeholder: 'Longitudine',
                  },
                  {
                    name: 'timezone',
                    type: 'timezone',
                    placeholder: 'Timezone',
                  },
                  {
                    name: 'google_places_link',
                    type: 'text',
                    placeholder: 'Link a Google Places',
                  },
                  {
                    name: 'facebook_link',
                    type: 'text',
                    placeholder: 'Link a Facebook',
                  },
                  {
                    name: 'tripadvisor_link',
                    type: 'text',
                    placeholder: 'Link a Tripadvisor',
                  },
                ]}
                onSubmit={async (values: RequestFilters.LocationUpdate) => {
                  setLoading(true);
                  sdk.updateLocation({...values}).then(loadData)
                }}
              />
            </div>
          </div>
        )
      }
    </div>
  );
}
