export class JWT
{
  // decodeToken(token: string): any;
  // getTokenExpirationDate(token: string): Date;
  // isTokenExpired(token: string, offsetSeconds?: number): boolean;

  // credits for decoder goes to https://github.com/atk
  static b64decode(str: string): string
  {
    const chars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output: string = '';
    str = String(str).replace(/=+$/, '');
    if (str.length % 4 === 1) {
      throw new Error('"atob" failed: The string to be decoded is not correctly encoded.');
    }
    for (
      // initialize result and counters
      let bc: number = 0, bs: any = void 0, buffer: any = void 0, idx: number = 0;
      // get next character
      buffer = str.charAt(idx++);
      // character found in table? initialize bit storage and add its ascii value;
      // tslint:disable-next-line:no-bitwise
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
          // and if not first of each 4 characters,
          // convert the first 8 bits to one ascii character
          // tslint:disable-next-line:no-bitwise
          bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
  static b64DecodeUnicode(str: string): string
  {
    return decodeURIComponent(Array.prototype.map.call(JWT.b64decode(str), function (c: string): string {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  static urlBase64Decode(str: string): string
  {
    let output: string = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return JWT.b64DecodeUnicode(output);
  }

  static decodeToken(token: string): any
  {
    const parts: string[] = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded: string = JWT.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  static getTokenExpirationDate(token: string): Date | undefined
  {
    const decoded: any = JWT.decodeToken(token);
    if (!decoded.hasOwnProperty('exp')) {
      return undefined;
    }
    const date: Date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  static isTokenExpired(token: string, offsetSeconds?: number): boolean
  {
    const date: Date | undefined = JWT.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;
    if (date === undefined) {
      return false;
    }
    // Token expired?
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
  }
}
