// import { JWT } from './../../../lib/utils/jwt';
import { BaseSDK, ResponseSDKBase } from '../../../lib/sdk/core/BaseSDK';
import { Headers } from '../../../lib/sdk/interfaces/Headers';
import { RequestHandler } from '../../../lib/sdk/interfaces/RequestHandler';

import { ResponseResults } from '../interfaces/ResponseResults';
import { RequestFilters } from '../interfaces/RequestFilters';
import { Environment, Constants } from '../../constants';

export class AppSDK<R extends RequestHandler> extends BaseSDK<R> {
  protected baseUrl: string;
  protected multipartHeader = new Headers(
    [
      ['Content-Type', 'multipart/form-data']
    ]
  )

  constructor(
    protected builderRequest: new (defaultHeaders?: Headers) => R,
    protected env: Environment = 'production',
    // protected authorization: ResponseResults.Authorization,
  ) {
    super(builderRequest);
    this.baseUrl = Constants.API.BASEURL[this.env] || Constants.API.BASEURL.production;
  }

  protected buildMultipartRequestOptions({ file, data, method }: {
    file: [string, any],
    data: [string, any][],
    method: 'get' | 'post' | 'put' | 'delete'
  }) {
    const fd = new FormData();
    fd.append('_method', method);
    fd.append(file[0], file[1]);
    data.forEach(d => {
      // console.log(d);
      if (Array.isArray(d[1])) {
        fd.append(d[0], JSON.stringify(d[1]));
      } else {
        fd.append(d[0],
          JSON.stringify(
            // remove void values
            Object.fromEntries(
              Object.entries(d[1])
                .filter(
                  ([, value]) => value !== undefined && value !== null// && value !== ''
                )
            )
          )
        );
      }
    })

    return {
      data: fd,
      headers: this.multipartHeader,
    }
  }

  // private buildHeaders(): Promise<boolean> {
  //   // this.headers['X-Request-ID'] = this.platform.getID();
  //   // this.defaultHeaders.set()
  //   return this.isTokenExpired().then(isTokenExpired => {
  //     if (!isTokenExpired && Utils.isDefined(this.token)) {
  //       this.defaultHeaders.set('Authorization', 'Bearer ' + this.token);
  //     }
  //     return true;
  //   });
  // }

  // private isTokenExpired(): Promise<boolean> {
  //   // console.log('is token expired');
  //   return this.storage
  //     .get('token')
  //     .then((token: string) => {
  //       if (!JWT.isTokenExpired(token)) {
  //         this.token = token;
  //         return false;
  //       } else {
  //         // console.log('chiamo il token expired');
  //         return this.refreshToken().then((newToken: string) => {
  //           // console.log('prova', newToken);
  //           if (Utils.isNotDefined(newToken)) {
  //             return true;
  //           }
  //           this.token = newToken;
  //           return false;
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       console.log('token not found');
  //       return true;
  //     }); // token not found
  // }

  /* TODO: AUTH - spostare in lib (e aggiungere con mixin) */
  // public async authenticate(apiKey: string, apiSecret: string): Promise<boolean>;
  public async authenticate(credentials: RequestFilters.Credentials): Promise<ResponseSDKBase<ResponseResults.AuthLogin>> {
    // const bearerResult: ResponseResults.Authorization = await this.getBearer(credentials);
    // this.setAuthorization(bearerResult.bearer);
    // return true;
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.AuthLogin>>(this.baseUrl + 'auth/login', {
      data: {
        ...credentials,
      }
    });
    return response;
  }

  // public async getBearer({ email, password }: RequestFilters.Credentials): Promise<ResponseSDKBase<ResponseResults.Authorization>> {
  //   const request: R = new this.builderRequest(this.defaultHeaders);
  //   const response = await request.post<ResponseSDKBase<ResponseResults.AuthLogin>>(this.baseUrl + 'auth/login', {
  //   // const response = await request.post<ResponseResults.AuthLogin>(this.baseUrl + 'auth/login', {
  //     data: {
  //       email,
  //       password,
  //     }
  //   });
  //   if (response.status >= 300) {
  //     throw new Error();
  //   }
  //   return {
  //     bearer: response.results.access_token,
  //     expiresIn: response.results.expires_in
  //     // bearer: response.access_token,
  //     // expiresIn: response.expires_in
  //   };
  // }

  public setAuthorization(bearer: string): void {
    this.defaultHeaders.set('Authorization', 'Bearer ' + bearer);
  }

  public async logout(): Promise<undefined> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    await request.post<ResponseSDKBase<null>>(this.baseUrl + 'auth/logout');
    // if (response.status >= 300) {
    //   throw new Error();
    // }
    return undefined;
  }
  /* ./AUTH */

  public async getUsers(): Promise<ResponseSDKBase<ResponseResults.User[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.User[]>>(
      this.baseUrl + 'users'
    );
    return response;
  }

  public async getMe(): Promise<ResponseSDKBase<ResponseResults.User>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.User>>(
      this.baseUrl + 'users/me'
    );
    return response;
  }

  public async getPlan(): Promise<ResponseSDKBase<ResponseResults.User>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.User>>(
      this.baseUrl + 'plan'
    );
    return response;
  }

  public async getMenus(): Promise<ResponseSDKBase<ResponseResults.Menu[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Menu[]>>(
      this.baseUrl + 'menus'
    );
    return response;
  }

  public async getDishes(): Promise<ResponseSDKBase<ResponseResults.Dish[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Dish[]>>(
      this.baseUrl + 'dishes',
      {
        data: {
          includes: ['menu_sections']
        }
      }
    );
    return response;
  }

  public async deleteDish(id: number): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes/' + id,
    );
    return response;
  }

  public async getMenu(id: number): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menus/' + id,
      {
        data: {
          includes: ['sections']
        }
      }
    );
    return response;
  }

  public async deleteMenu(id: number): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menus/' + id,
    );
    return response;
  }

  public async createMenus(data: RequestFilters.MenuCreate): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menus',
      {
        data: {
          menu: data
        }
      }
    );
    return response;
  }

  public async updateMenus({id, image, ...data}: RequestFilters.MenuUpdate): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);

    let response;
    if (typeof image === 'object') {
      response = await request.post<ResponseSDKBase<ResponseResults.Menu>>(
        this.baseUrl + 'menus/' + id,
        this.buildMultipartRequestOptions({
          file: ['image', image],
          data: [['menu', data]/*, ['includes', ['sections']]*/],
          method: 'put',
        })
      );
    } else {
      response = await request.put<ResponseSDKBase<ResponseResults.Menu>>(
        this.baseUrl + 'menus/' + id,
        {
          data: {
            menu: data,
          },
        }
      );
    }

    return response;
  }

  public async menusSort(data: {id: number, order: number}[]): Promise<ResponseSDKBase<ResponseResults.Menu[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Menu[]>>(
      this.baseUrl + 'menus/sortGroup',
      {
        data: {
          menus: data
        }
      },
    );
    return response;
  }

  public async menuSectionSort(data: {id: number, order: number}[]): Promise<ResponseSDKBase<ResponseResults.MenuSection[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.MenuSection[]>>(
      this.baseUrl + 'menusections/sortGroup',
      {
        data: {
          menusection: data
        }
      },
    );
    return response;
  }

  public async menuSectionDishSort(data: {id: number, order: number}[]): Promise<ResponseSDKBase<any[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<any[]>>(
      this.baseUrl + 'menusectiondishes/sortGroup',
      {
        data: {
          menusectiondish: data
        }
      },
    );
    return response;
  }

  public async recipesSort(data: {id: number, order: number}[]): Promise<ResponseSDKBase<any[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<any[]>>(
      this.baseUrl + 'recipes/sortGroup',
      {
        data: {
          menusection: data
        }
      },
    );
    return response;
  }

  // public async getMenuSections(menu_id: number): Promise<ResponseSDKBase<ResponseResults.Menu>> {
  //   const request: R = new this.builderRequest(this.defaultHeaders);
  //   const response = await request.get<ResponseSDKBase<ResponseResults.Menu>>(
  //     this.baseUrl + 'menusections',
  //     {
  //       data: {
  //         menusection: {

  //         }
  //       }
  //     }
  //   );
  //   return response;
  // }

  public async createMenuSection(data: RequestFilters.MenuSectionCreate): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menusections',
      {
        data: {
          menusection: data
        }
      }
    );
    return response;
  }

  public async updateMenuSection({id, image, ...data}: RequestFilters.MenuSectionUpdate): Promise<ResponseSDKBase<ResponseResults.MenuSection>> {
    const request: R = new this.builderRequest(this.defaultHeaders);

    let response;
    if (typeof image === 'object') {
      response = await request.post<ResponseSDKBase<ResponseResults.MenuSection>>(
        this.baseUrl + 'menusections/' + id,
        this.buildMultipartRequestOptions({
          file: ['image', image],
          data: [['menusection', data]/*, ['includes', ['sections']]*/],
          method: 'put',
        })
      );
    } else {
      response = await request.put<ResponseSDKBase<ResponseResults.MenuSection>>(
        this.baseUrl + 'menusections/' + id,
        {
          data: {
            menusection: data,
          },
        }
      );
    }

    return response;
  }

  public async updateMenuSectionAddDish({menu_section_id, dish_id}: {
    menu_section_id: string,
    dish_id: string,
  }): Promise<ResponseSDKBase<ResponseResults.MenuSection[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.MenuSection[]>>(
      this.baseUrl + 'menusections/' + menu_section_id,
      {
        data: {
          menusection: {
            dish_id,
          },
        }
      }
    );
    return response;
  }

  public async removeMenuSectionDish({id}: {
    id: number,
  }): Promise<ResponseSDKBase<ResponseResults.MenuSection>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.MenuSection>>(
      this.baseUrl + `menusectiondishes/${id}`,
    );
    return response;
  }

  public async getMenuSection(id: number): Promise<ResponseSDKBase<ResponseResults.MenuSection>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.MenuSection>>(
      this.baseUrl + 'menusections/' + id,
      {
        data: {
          includes: ['dishes']
        }
      }
    );
    return response;
  }

  public async deleteMenuSection(id: number): Promise<ResponseSDKBase<ResponseResults.MenuSection>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.MenuSection>>(
      this.baseUrl + 'menusections/' + id,
    );
    return response;
  }

  public async getMenuSectionDish(id: number): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes/' + id,
      {
        data: {
          includes: ['ingredients', 'tags', 'menu_sections', 'menu_sections.menu', 'provenance']
        }
      }
    );
    return response;
  }

  public async createDish(data: RequestFilters.DishCreate): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes',
      {
        data: {
          dish: data
        }
      }
    );
    return response;
  }

  public async updateDish({id, image, ...data}: RequestFilters.DishUpdate): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);

    let response;
    if (typeof image === 'object') {
      response = await request.post<ResponseSDKBase<ResponseResults.Dish>>(
        this.baseUrl + 'dishes/' + id,
        this.buildMultipartRequestOptions({
          file: ['image', image],
          data: [['dish', data]/*, ['includes', ['sections']]*/],
          method: 'put',
        })
      );
    } else {
      response = await request.put<ResponseSDKBase<ResponseResults.Dish>>(
        this.baseUrl + 'dishes/' + id,
        {
          data: {
            dish: data,
          },
        }
      );
    }

    return response;
  }

  public async createIngredient(data: RequestFilters.IngredientCreate): Promise<ResponseSDKBase<ResponseResults.Ingredient>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Ingredient>>(
      this.baseUrl + 'ingredients',
      {
        data: {
          ingredient: data,
        }
      }
    );
    return response;
  }

  public async getIngredients(): Promise<ResponseSDKBase<ResponseResults.Ingredient[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Ingredient[]>>(
      this.baseUrl + 'ingredients',
      {
        data: {
          includes: ['dishes']
        }
      }
    );
    return response;
  }

  public async getIngredient(id: number): Promise<ResponseSDKBase<ResponseResults.Ingredient>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Ingredient>>(
      this.baseUrl + 'ingredients/' + id,
      {
        data: {
          includes: ['allergens', 'dishes', 'dishes.menu_sections', 'dishes.menu_sections.menu']
        }
      }
    );
    return response;
  }

  public async updateIngredient({ id, ...data }: RequestFilters.IngredientUpdate): Promise<ResponseSDKBase<ResponseResults.Ingredient>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Ingredient>>(
      this.baseUrl + 'ingredients/' + id,
      {
        data: {
          ingredient: data,
        }
      }
    );
    return response;
  }

  public async deleteIngredient(id: number): Promise<ResponseSDKBase<ResponseResults.Ingredient>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.Ingredient>>(
      this.baseUrl + 'ingredients/' + id,
    );
    return response;
  }

  public async removeIngredient(data: RequestFilters.IngredientRemove): Promise<ResponseSDKBase<ResponseResults.Ingredient>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.Ingredient>>(
      this.baseUrl + `dishes/${data.dish_id}/ingredients/${data.ingredient_id}`,
    );
    return response;
  }

  public async removeProvenanceFromDish(id: number): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes/' + id,
      {
        data: {
          dish: {
            provenance_id: false,
          },
        }
      }
    );
    return response;
  }

  public async updateDishAddIngredient({dish_id, ingredient_id}: {
    dish_id: string,
    ingredient_id: string,
  }): Promise<ResponseSDKBase<ResponseResults.Ingredient[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Ingredient[]>>(
      this.baseUrl + 'dishes/' + dish_id,
      {
        data: {
          dish: {
            ingredient_id,
          },
          // includes: ['ingredients']
        }
      }
    );
    return response;
  }

  public async createTag(data: RequestFilters.TagCreate): Promise<ResponseSDKBase<ResponseResults.Tag>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Tag>>(
      this.baseUrl + 'tags',
      {
        data: {
          tag: data,
        }
      }
    );
    return response;
  }

  public async removeTag(data: RequestFilters.TagRemove): Promise<ResponseSDKBase<ResponseResults.Tag>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.Tag>>(
      this.baseUrl + `dishes/${data.dish_id}/tags/${data.tag_id}`,
    );
    return response;
  }

  public async getTags(): Promise<ResponseSDKBase<ResponseResults.Tag[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Tag[]>>(
      this.baseUrl + 'tags',
      {
        // data: {
        //   includes: ['allergens']
        // }
      }
    );
    return response;
  }

  public async createProvenance(data: RequestFilters.ProvenanceCreate): Promise<ResponseSDKBase<ResponseResults.Provenance>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Provenance>>(
      this.baseUrl + 'provenances',
      {
        data: {
          provenance: data,
        }
      }
    );
    return response;
  }

  public async updateDishAddProvenance({dish_id, provenance_id}: {
    dish_id: string,
    provenance_id: string,
  }): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes/' + dish_id,
      {
        data: {
          dish: {
            provenance_id,
          },
        }
      }
    );
    return response;
  }

  public async getAllergens(): Promise<ResponseSDKBase<ResponseResults.Allergen[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Allergen[]>>(
      this.baseUrl + 'allergens',
      {
      }
    );
    return response;
  }

  public async getProvenances(): Promise<ResponseSDKBase<ResponseResults.Provenance[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Provenance[]>>(
      this.baseUrl + 'provenances',
      {
      }
    );
    return response;
  }

  public async updateDishAddTag({dish_id, tag_id}: {
    dish_id: string,
    tag_id: string,
  }): Promise<ResponseSDKBase<ResponseResults.Tag[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Tag[]>>(
      this.baseUrl + 'dishes/' + dish_id,
      {
        data: {
          dish: {
            tag_id,
          },
          // includes: ['tags']
        }
      }
    );
    return response;
  }

  public async getLocation(id: number): Promise<ResponseSDKBase<ResponseResults.Location>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Location>>(
      this.baseUrl + 'locations/' + id,
      {
        data: {
          includes: []
        }
      }
    );
    return response;
  }

  public async updateLocation({ id, ...data }: RequestFilters.LocationUpdate): Promise<ResponseSDKBase<ResponseResults.Location>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Location>>(
      this.baseUrl + 'locations/' + id,
      {
        data: {
          location: data,
        }
      }
    );
    return response;
  }

  public async getRooms(): Promise<ResponseSDKBase<ResponseResults.Room[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Room[]>>(
      this.baseUrl + 'rooms',
      {
        data: {
          includes: ['tables']
        }
      }
    );
    return response;
  }

  public async createRoom(data: RequestFilters.RoomCreate): Promise<ResponseSDKBase<ResponseResults.Room>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Room>>(
      this.baseUrl + 'rooms',
      {
        data: {
          room: data
        }
      }
    );
    return response;
  }

  public async getRoom(id: number): Promise<ResponseSDKBase<ResponseResults.Room>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Room>>(
      this.baseUrl + 'rooms/' + id,
      {
        data: {
          includes: ['tables']
        }
      }
    );
    return response;
  }

  public async updateRoom({ id, ...data }: RequestFilters.RoomUpdate): Promise<ResponseSDKBase<ResponseResults.Room>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Room>>(
      this.baseUrl + 'rooms/' + id,
      {
        data: {
          room: data,
        }
      }
    );
    return response;
  }

  public async getTables(): Promise<ResponseSDKBase<ResponseResults.Table[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Table[]>>(
      this.baseUrl + 'tables',
      {
        data: {
          includes: ['room', 'reservations.slots']
        }
      }
    );
    return response;
  }

  public async createTable(data: RequestFilters.TableCreate): Promise<ResponseSDKBase<ResponseResults.Table>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Table>>(
      this.baseUrl + 'tables',
      {
        data: {
          table: data
        }
      }
    );
    return response;
  }

  public async getTable(id: number): Promise<ResponseSDKBase<ResponseResults.Table>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Table>>(
      this.baseUrl + 'tables/' + id,
      {
        data: {
          includes: []
        }
      }
    );
    return response;
  }

  public async updateTable({ id, ...data }: RequestFilters.TableUpdate): Promise<ResponseSDKBase<ResponseResults.Table>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Table>>(
      this.baseUrl + 'tables/' + id,
      {
        data: {
          table : data,
        }
      }
    );
    return response;
  }

  public async getReservations(date?: Date | string): Promise<ResponseSDKBase<ResponseResults.Reservation[]>> {
    let filter_groups: any[] = [];
    if (date) {
      filter_groups = [
        {
          filters: [
            {
              key: 'date',
              operator: 'eq',
              value: date,
            }
          ]
        }
      ];
    }
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Reservation[]>>(
      this.baseUrl + 'reservations',
      {
        data: {
          includes: ['slots', 'customer', 'allergies', 'tables'],
          filter_groups,
        }
      }
    );
    return response;
  }

  public async getReservationsAvailabilities(date: string, guests: number): Promise<ResponseSDKBase<ResponseResults.ReservationsAvailabilities[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.ReservationsAvailabilities[]>>(
      this.baseUrl + 'reservations/availabilities/' + date,
      {
        data: {
          includes: [],
          guests,
        }
      }
    );
    return response;
  }

  public async getTablesAvailabilities(date: string): Promise<ResponseSDKBase<ResponseResults.TablesAvailabilities[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.TablesAvailabilities[]>>(
      this.baseUrl + 'tables/availabilities/' + date,
      {
        data: {
          includes: []
        }
      }
    );
    return response;
  }

  public async createReservation(data: RequestFilters.ReservationCreate): Promise<ResponseSDKBase<ResponseResults.Reservation>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Reservation>>(
      this.baseUrl + 'reservations',
      {
        data: {
          reservation: data
        }
      }
    );
    return response;
  }

  public async createCustomer(data: RequestFilters.CustomerCreate): Promise<ResponseSDKBase<ResponseResults.Customer>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Customer>>(
      this.baseUrl + 'customers',
      {
        data: {
          customer: data
        }
      }
    );
    return response;
  }

  public async getCustomer(email: string): Promise<ResponseSDKBase<ResponseResults.Customer>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Customer>>(
      this.baseUrl + 'customers/' + email,
      {
        data: {
          includes: []
        }
      }
    );
    return response;
  }

  public async updateReservation(data: RequestFilters.ReservationUpdate): Promise<ResponseSDKBase<ResponseResults.Reservation>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Reservation>>(
      this.baseUrl + 'reservations/' + data.id,
      {
        data: {
          reservation: data,
        }
      }
    );
    return response;
  }

  public async updateReservationSetConfirmed(id: string): Promise<ResponseSDKBase<ResponseResults.Reservation>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Reservation>>(
      this.baseUrl + 'reservations/' + id,
      {
        data: {
          reservation: {
            state: 'confirmed'
          },
        }
      }
    );
    return response;
  }

  public async updateReservationSetArrived(id: string): Promise<ResponseSDKBase<ResponseResults.Reservation>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Reservation>>(
      this.baseUrl + 'reservations/' + id,
      {
        data: {
          reservation: {
            state: 'arrived'
          },
        }
      }
    );
    return response;
  }

  public async updateReservationSetCancelled(id: string): Promise<ResponseSDKBase<ResponseResults.Reservation>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Reservation>>(
      this.baseUrl + 'reservations/' + id,
      {
        data: {
          reservation: {
            state: 'cancelled'
          },
        }
      }
    );
    return response;
  }

  public async updateReservationAddTable({reservation_id, table_ids}: {
    reservation_id: string,
    table_ids: number[],
  }): Promise<ResponseSDKBase<ResponseResults.Reservation>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Reservation>>(
      this.baseUrl + 'reservations/' + reservation_id,
      {
        data: {
          reservation: {
            table_ids,
          },
        }
      }
    );
    return response;
  }

  public async updateReservationRemoveTable({reservation_id}: {
    reservation_id: string,
  }): Promise<ResponseSDKBase<ResponseResults.Reservation>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Reservation>>(
      this.baseUrl + 'reservations/' + reservation_id,
      {
        data: {
          reservation: {
            table_ids: [],
          },
        }
      }
    );
    return response;
  }

  public async getOpeningTimes(type: 'standard' | 'special' = 'standard'): Promise<ResponseSDKBase<ResponseResults.OpeningTime[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.OpeningTime[]>>(
      this.baseUrl + 'openingtimes',
      {
        data: {
          openingtimes: {
            type,
          },
          includes: []
        }
      }
    );
    return response;
  }

  public async createBulkOpeningTimes(data: RequestFilters.OpeningTime[], type: 'standard' | 'special' = 'standard'): Promise<ResponseSDKBase<ResponseResults.OpeningTime[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.OpeningTime[]>>(
      this.baseUrl + 'openingtimes/bulk',
      {
        data: {
          openingtimes: data.map(d => ({
            type,
            location_id: 1,
            ...d,
          }))
        }
      }
    );
    return response;
  }

  public async deleteBulkOpeningTimes(data: {id: number, type: 'standard' | 'special' }[]): Promise<ResponseSDKBase<ResponseResults.OpeningTime[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.OpeningTime[]>>(
      this.baseUrl + 'openingtimes/bulk',
      {
        data: {
          openingtimes: data
        }
      }
    );
    return response;
  }

  public async getSlotDefinitions(type: 'standard' | 'special' = 'standard'): Promise<ResponseSDKBase<ResponseResults.SlotDefinition[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.SlotDefinition[]>>(
      this.baseUrl + 'slotdefinitions',
      {
        data: {
          slotdefinitions: {
            type,
          },
          includes: []
        }
      }
    );
    return response;
  }

  public async createBulkSlotDefinitions(data: RequestFilters.SlotDefinition[], type: 'standard' | 'special' = 'standard'): Promise<ResponseSDKBase<ResponseResults.SlotDefinition[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.SlotDefinition[]>>(
      this.baseUrl + 'slotdefinitions/bulk',
      {
        data: {
          slotdefinitions: data.map(d => ({
            type,
            location_id: 1,
            ...d,
          }))
        }
      }
    );
    return response;
  }

  public async deleteBulkSlotDefinitions(data: {id: number, type: 'standard' | 'special' }[]): Promise<ResponseSDKBase<ResponseResults.SlotDefinition[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.delete<ResponseSDKBase<ResponseResults.SlotDefinition[]>>(
      this.baseUrl + 'slotdefinitions/bulk',
      {
        data: {
          slotdefinitions: data
        }
      }
    );
    return response;
  }

  public async getCustomers(): Promise<ResponseSDKBase<ResponseResults.Customer[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Customer[]>>(
      this.baseUrl + 'customers',
      {
        data: {
          includes: [
            'reservations',
            'legalDocuments',
          ]
        }
      }
    );
    return response;
  }

  public async getSatisfactionSurveyResponses(): Promise<ResponseSDKBase<ResponseResults.SurveyResponse[]>> {
    const survey_satisfaction_id = (await this.getLocation(1)).results.survey_satisfaction_id

    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.SurveyResponse[]>>(
      this.baseUrl + 'surveys/' + survey_satisfaction_id + '/responses',
      {
        data: {
          includes: []
        }
      }
    );
    return response;
  }
}
