import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import { Link, useHistory, useParams } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import { UpdateForm } from '../components/organisms/updateForm.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { Utils } from '../../../lib/utils/core';

export default function Dish(props: any): JSX.Element {
  const params = useParams<{menuId: string, sectionId: string, ingredientId: string}>()

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [ingredient, setIngredient] = useState<ResponseResults.Ingredient>();
  const [allergens, setAllergens] = useState<ResponseResults.Allergen[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    Promise.all([
      sdk.getIngredient(Number(params.ingredientId))
        .then(res => setIngredient(res.results)),
      sdk.getAllergens()
        .then(res => setAllergens(res.results))
    ])
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, []);

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Ingrediente</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            {ingredient?.dishes && ingredient?.dishes.length > 0 && (
              <div className="alert alert-warning" role="alert">
                <p>
                  Attenzione! Modificando qui questo ingrediente, lo modificherai anche nei seguenti piatti:
                </p>
                <ul>
                  {ingredient?.dishes?./*flatMap(d => d.menu_sections?.*/
                    map(ms => ({
                      // label: `${d.name} (${ms.menu?.name})`,
                      // url: `/menus/${ms.menu?.id}/section/${ms.id}/dish/${d.id}`,
                      label: ms.name,
                      url: `/dishes/${ms.id}`,
                    })).
                    map(({ label, url }: any, idx: number) => (
                      <li key={`related-${idx}`}>
                        <Link to={url}>
                          <span>{label}</span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            <div className="tmp-form-ingredients">
              <UpdateForm
                model={ingredient}
                loading={loading}
                fields={[
                  {
                    name: '',
                    type: 'label',
                    placeholder: 'Nome ingrediente',
                  },
                  {
                    name: 'name',
                    type: 'text',
                    placeholder: 'Enter menu section name...',
                  },
                  {
                    name: '',
                    type: 'label',
                    placeholder: 'Tipologia',
                  },
                  {
                    name: 'vegan',
                    type: 'switch',
                    placeholder: 'vegan',
                  },
                  {
                    name: 'bio',
                    type: 'switch',
                    placeholder: 'bio',
                  },
                  {
                    name: 'frozen',
                    type: 'switch',
                    placeholder: 'frozen',
                  },
                  {
                    name: 'vegetarian',
                    type: 'switch',
                    placeholder: 'vegetarian',
                  },
                  {
                    name: '',
                    type: 'label',
                    placeholder: 'Allergeni',
                  },
                  ...allergens.map(a => ({
                    name: `allergens.${a.name}`,
                    type: 'switch',
                    placeholder: a.name,
                  })),
                ]}
                onSubmit={async (values: RequestFilters.IngredientUpdate) => {
                  setLoading(true);
                  sdk.updateIngredient(values).then(loadData)
                }}
              />
            </div>
            <button className="btn btn-danger btn-block mt-2" onClick={() => {
              if (window.confirm('Vuoi eliminare definitivamente l\'ingrediente ' + ingredient?.name + '?')) {
                setLoading(true);
                sdk.deleteIngredient((ingredient as ResponseResults.Ingredient).id)
                  .then(() => {
                    history.replace('/ingredients');
                  })
                  .catch(e => setErrors(Utils.formatErrors(e)))
                  .finally(() => setLoading(false))
              }
            }}>Delete</button>
          </div>
        )
      }
    </div>
  );
}
