import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../components/atoms/loader';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import { UpdateForm } from '../components/organisms/updateForm.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { Utils } from '../../../lib/utils/core';
import List, { ListArgs } from '../components/organisms/list.component';

export default function Room(props: any): JSX.Element {
  const params = useParams<{roomId: string}>()
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [room, setRoom] = useState<ResponseResults.Room>();
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getRoom(Number(params.roomId))
      .then(res => {
        setRoom(res.results);
        console.log(res.results)
      })
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, []);

  const ListTable = List as React.FC<ListArgs<ResponseResults.Table>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Locale</h1>

      {(loading || !room)
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            <div>
              <UpdateForm
                model={room}
                loading={loading}
                fields={[
                  {
                    name: 'name',
                    type: 'text',
                    placeholder: 'Nome del locale',
                  },
                  {
                    name: 'is_active',
                    type: 'switch',
                    placeholder: 'Attiva',
                  },
                ]}
                onSubmit={async (values: RequestFilters.RoomUpdate) => {
                  setLoading(true);
                  sdk.updateRoom({...values}).then(loadData)
                }}
              />
            </div>

            <div className='mt-5'>
              <ListTable
                model={room.tables}
                title=""
                addPlaceholder="Add table"
                generateListLink={(model: any) => {
                  return `/rooms/${room.id}/tables/${model.id}`;
                }}
                onCreateNew={(value: string) => {
                  setLoading(true);
                  sdk.createTable({
                    name: value,
                    capacity: 0,
                    room_id: room.id,
                  })
                    .then((table => history.push('/rooms/' + room.id +'/tables/' + table.results.id)))
                    .catch(e => setErrors(Utils.formatErrors(e)))
                }}
              />
            </div>
          </div>
        )
      }
    </div>
  );
}
