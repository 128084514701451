import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import List, { ListArgs } from '../components/organisms/list.component';
import { Utils } from '../../../lib/utils/core';

export default function Rooms(props: any): JSX.Element {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [rooms, setRooms] = useState<ResponseResults.Room[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getRooms()
      .then(res => setRooms(res.results))
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData();
  }, []);

  const ListRoom = List as React.FC<ListArgs<ResponseResults.Room>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Sale</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            <ListRoom
              model={rooms}
              title=""
              addPlaceholder="Add room"
              generateListLink={(model: any) => {
                return `/rooms/${model.id}`;
              }}
              onCreateNew={(value: string) => {
                setLoading(true);
                sdk.createRoom({
                  name: value,
                  is_active: true,
                  location_id: 1,
                })
                  .then((room => history.push('/rooms/' + room.results.id)))
                  .catch(e => setErrors(Utils.formatErrors(e)))
              }}
            />
          </div>
        )
      }
    </div>
  );
}
