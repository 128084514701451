import { takeLatest, call, put } from '@redux-saga/core/effects';

import { Utils } from '../../../lib/utils/core';
import { ResponseBase } from '../../../lib/sdk/interfaces/RequestHandler';

import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import sdk from '../../../app-lib/sdk/sdk.service';

import { USER } from './reducer/types';
// import { getAuth } from '../auth/auth.saga';
// import { AuthState } from '../auth/reducer/reducer';
import { userMeFailure, userMeSuccess } from './reducer/actions';
// import { logoutRequest } from '../auth/reducer/actions';

export function* getMe(): Generator {
  // const auth = (yield select(getAuth)) as AuthState;
  // if (!auth.token) {
  //   return;
  // }
  try {
    const me = (yield call(sdk.getMe.bind(sdk))) as ResponseBase<ResponseResults.AuthLogin>;
    const plan = (yield call(sdk.getPlan.bind(sdk))) as ResponseBase<ResponseResults.AuthLogin>;
    yield put(userMeSuccess({...me.results, plan: plan.results}));
  } catch (e) {
    // console.log(e);z
    yield put(userMeFailure(Utils.formatErrors(e)));
    // yield put(logoutRequest());
  }
}

export default [takeLatest(USER.USER_ME_REQUEST, getMe)];
