import { NetworkError } from './../../../lib/utils/errors';
import { takeLatest, call, put, select } from '@redux-saga/core/effects';

import { Utils } from '../../../lib/utils/core';
import { ResponseBase } from '../../../lib/sdk/interfaces/RequestHandler';
import { ActionInterface } from '../../../lib/redux/models/action';
import { observeStore } from '../../../lib/redux/store/helper';

import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import sdk from '../../../app-lib/sdk/sdk.service';

import { getStore } from './../../store/index';
import { AppState } from './../../store/reducers/index';
import { userMeRequest } from '../user/reducer/actions';

import { AUTH } from './reducer/types';
import { loginFailure, loginSuccess, logoutSuccess, logoutFailure } from './reducer/actions';
import { AuthState } from './reducer/reducer';

export const getAuth = (state: AppState): AuthState => state.auth;

export const automaticLogout = (): void => {
  const { store } = getStore();
  observeStore(
    store,
    (state: AppState) => state.auth.isAuthenticated,
    (isAuthenticated: boolean) => {
      console.log('********************l\'auth è', isAuthenticated)
      // if (!isAuthenticated) {
      //   window.location.reload();
      // }
    }
  );
}

export function* login({ data }: ActionInterface<RequestFilters.Credentials>): Generator {
  try {
    const response = (yield call(sdk.authenticate.bind(sdk), data)) as ResponseBase<ResponseResults.AuthLogin>;
    sdk.setAuthorization(response.results.access_token as string);
    yield put(loginSuccess(response.results));
    yield select(automaticLogout);
    // yield call(sdk.authenticate.bind(sdk), data);
    // yield put(loginSuccess());
    yield put(userMeRequest());
  } catch (e) {
    console.log(e);
    yield put(loginFailure(Utils.formatErrors(e)));
  }
}

export function* logout(): Generator {
  try {
    // yield call(sdk.logout.bind(sdk));
    yield put(logoutSuccess());
  } catch (e) {
    if(e instanceof NetworkError){
      console.log('There was a network error.');
    }
    yield put(logoutFailure(Utils.formatErrors(e)));
  }
}

export default [
  takeLatest(AUTH.LOGIN_REQUEST, login),
  takeLatest(AUTH.LOGOUT_REQUEST, logout),
];
