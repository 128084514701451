import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Loader from '../components/atoms/loader';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import { Utils } from '../../../lib/utils/core';
import { DayOfWeek } from '../../../lib/sdk/interfaces/generics';
import Accordion from '../components/organisms/accordion.component';

const slotDuration = 15; // TODO: use as global constant: the duration of single slot

const TimePicker = (props: {
  initialValue: number;
  label: string;
  step: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}) => {
  const [minutes, setMinutes] = useState(props.initialValue || 0);

  const handleChange = (e: any) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= props.min && value <= props.max) {
      setMinutes(value);
    }
    props.onChange && props.onChange(value);
  };

  const handleBlur = (e: any) => {
    let value = parseInt(e.target.value);
    if (isNaN(value) || value < props.min) {
      value = props.min;
    }
    if (value > props.max) {
      value = props.max;
    }
    setMinutes(value);
    props.onChange && props.onChange(value);
  };

  const timeOptions = [];
  for (let i = props.min; i <= props.max; i += props.step) {
    timeOptions.push(
      <option key={i} value={i}>
        {Utils.timeFormatterMtHM(i)}
      </option>
    );
  }

  const rand = Math.floor(Math.random() * 100000);

  return (
    <div className="form-group">
      <label className='mb-0' htmlFor={`timepicker-${rand}`}>{props.label}</label>
      <select
        className="form-control"
        id={`timepicker-${rand}`}
        value={minutes}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {timeOptions}
      </select>
    </div>
  );
};
const InputTimeSlot = ({defaultValue = 0, icon, action, validator, close}: {defaultValue?: number, icon: string; validator: (start: number, end: number) => boolean, action: (start: number, end: number) => void; close: () => void}) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const error = useMemo(() => {
    return !validator(start, end);
  }, [start, end])

  return (
    <div className={`row ${error ? 'border border-danger' : ''}`}>
      <div className='col-5'>
        <TimePicker
          initialValue={defaultValue}
          label="Inizio"
          step={slotDuration}
          min={0}
          max={24*60}
          onChange={val => setStart(val)}
        />
      </div>
      <div className='col-5'>
        <TimePicker
          initialValue={defaultValue}
          label="Fine"
          step={slotDuration}
          min={0}
          max={24*60}
          onChange={val => setEnd(val)}
        />
      </div>
      <div className='col-2 my-auto'>
        <a href='#' className='mr-2' role='button' onClick={() => close()}>
          <i className={`fas fa-lg fa-trash`}></i>
        </a>
        <a href='#' role='button' onClick={() => {
          if (validator(start, end)) {
            action(start, end)
          }
        }}>
          <i className={`fas fa-lg ${icon}`}></i>
        </a>
      </div>
    </div>
  );
}

const OpeningTimesContext = createContext<{
  error: boolean,
  slotDefinitions: ResponseResults.SlotDefinition[],
  setSlotDefinitions: (data: ResponseResults.SlotDefinition[]) => void,
  openingTimes: ResponseResults.OpeningTime[],
  setOpeningTimes: (data: ResponseResults.OpeningTime[]) => void,
  dayKey: 'day_of_week' | 'date',
  tables: ResponseResults.Table[],
}>({
  error: false,
  slotDefinitions: [],
  setSlotDefinitions: () => {},
  openingTimes: [],
  setOpeningTimes: () => {},
  dayKey: 'day_of_week',
  tables: [],
});

const Day: any = ({ name, dayValue }: { name: string, dayValue: string | number }) => {
  const { dayKey, openingTimes, slotDefinitions, setOpeningTimes, setSlotDefinitions, tables } = useContext(OpeningTimesContext);

  const daySlots = openingTimes.filter(ot => (ot as any)[dayKey] == dayValue)

  const isOpen = useMemo(() => {
    return daySlots.length > 0
  }, [daySlots]);

  const handleOpen = () => {
    if (!isOpen) {
      open();
    } else {
      close();
    }
  };

  const createDefinitionSlotForOpeningTime = (ot: ResponseResults.OpeningTime) => {
    for (let i = ot.opening_hour; i < ot.closing_hour; i = i + slotDuration) {
      const time = i;

      tables.forEach(t => {
        const v = {
          id: 0,
          [dayKey]: dayValue,
          start: time,
          end: time + slotDuration,
          reservable_within: 30,
          table_id: t.id
        };

        slotDefinitions.push(v as any);
      })
    }
    setSlotDefinitions([...slotDefinitions]);
  }

  const createOpeningTime = (open: number, close: number) => {
    const ot = {
      id: 0,
      opening_hour: open,
      closing_hour: close,
      [dayKey]: dayValue,
    };
    openingTimes.push(ot as any)
    setOpeningTimes([...openingTimes]);

    createDefinitionSlotForOpeningTime(ot as any)
  }

  const [showCreate, setShowCreate] = useState(false);
  const open = () => {
    setShowCreate(true)
  }

  const close = () => {
    // cancello tutti gli opening time di quel giorno
    setOpeningTimes([...openingTimes.filter(ot => (ot as any)[dayKey] != dayValue)]);

    // cancello tutti gli slot di quel giorno, a qualsiasi ora e per qualsiasi tavolo
    setSlotDefinitions([...slotDefinitions.filter(sd => (sd as any)[dayKey] != dayValue)]);
  }

  const isValidTimeSlot = (start: number, end: number) => {
    if (start >= end) {
      return false;
    }
    for (const daySlot of daySlots) {
      const existingStart = daySlot.opening_hour;
      const existingEnd = daySlot.closing_hour;
      if (start < existingEnd && end > existingStart) {
        // Ci sono sovrapposizioni, la nuova coppia non è valida
        return false;
      }
    }
    // Nessuna sovrapposizione trovata, la nuova coppia è valida
    return true;
  }

  return (
    <div>
      <div>
        <input className='mr-2' type="checkbox" checked={isOpen} onChange={handleOpen} />
        <span>{name}</span>
      </div>
      <div>
        <div className='mt-2'>
          {daySlots
            .sort((a,b) => a.opening_hour - b.opening_hour)
            .map((daySlot, i) => <DaySlot key={`dayslot-${name}-${i}`} model={daySlot} dayValue={dayValue} />)
          }
          {
            (daySlots.length > 0 && !showCreate) && (
              <div role='button' className='ml-3 pt-1 px-3 pb-2' onClick={() => setShowCreate(true)}>{'+ Nuovo slot'}</div>
            )
          }
        </div>
        {showCreate && (
          <div className='ml-4'>
            <InputTimeSlot
              defaultValue={daySlots.length === 0 ? 0 : daySlots.sort((a,b) => b.opening_hour - a.opening_hour)[0].closing_hour}
              icon={'fa-check'}
              // icon={(i === 0) ? 'fa-plus' : 'fa-trash'}
              close={() => {
                setShowCreate(false);
              }}
              validator={isValidTimeSlot}
              action={(start, end) => {
                createOpeningTime(start, end);
                setShowCreate(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const DaySlot = ({ model, dayValue }: { model: ResponseResults.OpeningTime, dayValue: string | number }) => {
  const { dayKey, openingTimes, setOpeningTimes, slotDefinitions, setSlotDefinitions } = useContext(OpeningTimesContext)
  const theoreticalSlots = (model.closing_hour - model.opening_hour) / slotDuration;


  const removeTimeSlot = () => {
    // cancello l'opening time corrente
    setOpeningTimes([...openingTimes.filter(ot => !((ot as any)[dayKey] == dayValue && ot.opening_hour == model.opening_hour && ot.closing_hour == model.closing_hour))]);

    // cancello gli slot del giorno corrente, nel range orario corrente, per qualsiasi tavolo
    setSlotDefinitions([...slotDefinitions.filter(sd => !((sd as any)[dayKey] == dayValue && sd.start >= model.opening_hour && sd.end <= model.closing_hour))]);
  }

  return (
    <div className='ml-3'>
      <Accordion
        title={
          <div>
            {Utils.timeFormatterMtHM(model.opening_hour)} - {Utils.timeFormatterMtHM(model.closing_hour)}
            <a href='#' className='ml-2' role='button' onClick={() => removeTimeSlot()}>
              <i className={`fas fa-lg fa-trash`}></i>
            </a>
          </div>
        }
      >
        <>
          {(new Array(theoreticalSlots)).fill(1).map((_, i) => {
            const slotStart = model.opening_hour + ( i * slotDuration);

            return (
              <SlotDefinition
                key={`${(model as any)[dayKey]}${model.id}theoreticalSlots${i}`}
                slotStart={slotStart}
                dayValue={dayValue}
              />
            )
          })}
        </>
      </Accordion>
    </div>
  )
}

const SlotDefinition = ({ slotStart, dayValue }: { slotStart: number, dayValue: string | number }) => {
  const { slotDefinitions, setSlotDefinitions, dayKey, tables } = useContext(OpeningTimesContext)

  const isOpen = useMemo(() => {
    return slotDefinitions.find(sd => sd.start === slotStart && (sd as any)[dayKey] == dayValue) !== undefined
  }, [slotDefinitions, slotStart]);

  const handleOpen = () => {
    if (!isOpen) {
      open();
    } else {
      close();
    }
  };

  const open = () => {
    tables.forEach(t => {
      const v = {
        id: 0,
        [dayKey]: dayValue,
        start: slotStart,
        end: slotStart + slotDuration,
        reservable_within: 30,
        table_id: t.id
      };

      slotDefinitions.push(v as any);
    })

    setSlotDefinitions([...slotDefinitions])
  }

  const close = () => {
    const sds = slotDefinitions.filter(sd => !(sd.start == slotStart && (sd as any)[dayKey] == dayValue))
    setSlotDefinitions([...sds])
  }

  return (
    <div>
      <input
        type="checkbox"
        className='mr-2'
        checked={isOpen}
        onChange={handleOpen}
      />
      {Utils.timeFormatterMtHM(slotStart)}
    </div>
  )
}

export default function OpeningTimes(props: any): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  const [initialOpeningTimes, setInitialOpeningTimes] = useState<ResponseResults.OpeningTime[]>([]);
  const [initialSlotDefinitions, setInitialSlotDefinitions] = useState<ResponseResults.SlotDefinition[]>([]);
  const [openingTimes, setOpeningTimes] = useState<ResponseResults.OpeningTime[]>([]);
  const [slotDefinitions, setSlotDefinitions] = useState<ResponseResults.SlotDefinition[]>([]);
  const [tables, setTables] = useState<ResponseResults.Table[]>([]);

  const weekDays = {
    1: 'Lunedì',
    2: 'Martedì',
    3: 'Mercoledì',
    4: 'Giovedì',
    5: 'Venerdì',
    6: 'Sabato',
    7: 'Domenica',
  }

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const ots = (await sdk.getOpeningTimes()).results;
      setOpeningTimes(ots);
      setInitialOpeningTimes([...ots]);

      const sds = (await sdk.getSlotDefinitions()).results;

      setSlotDefinitions(sds);
      setInitialSlotDefinitions([...sds]);

      const t= (await sdk.getTables()).results;
      setTables(t);
    } catch (e) {
      setErrors(Utils.formatErrors(e))
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData()
  }, []);

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Orari del locale</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className='row'>
            <div className="col-md-6">
              <h3>Giorni standard</h3>
              <OpeningTimesContext.Provider value={{error: false, dayKey: 'day_of_week', openingTimes, setOpeningTimes, slotDefinitions, setSlotDefinitions, tables}}>
                {Object.entries(weekDays).map(([i, dayOfWeek]) => (
                  <div key={`day-${i}`}>
                    <Day
                      name={dayOfWeek}
                      dayValue={Number(i)}
                      // dayValue={'2023-12-25'}
                    />
                  </div>
                ))}
              </OpeningTimesContext.Provider>

              <button className='btn btn-primary' onClick={async () => {
                setLoading(true);
                try {
                  if (initialOpeningTimes.length > 0) {
                    await sdk.deleteBulkOpeningTimes(initialOpeningTimes.map(ot => ({id: ot.id, type: 'standard'})));
                  }
                  if (openingTimes.length > 0) {
                    await sdk.createBulkOpeningTimes(openingTimes);
                  }

                  if (initialSlotDefinitions.length > 0) {
                    await sdk.deleteBulkSlotDefinitions(initialSlotDefinitions.map(sd => ({id: sd.id, type: 'standard'})))
                  }
                  if (slotDefinitions.length > 0) {
                    await sdk.createBulkSlotDefinitions(slotDefinitions);
                  }
                } catch(e) {
                  setErrors(Utils.formatErrors(e))
                } finally {
                  loadData();
                }
              }}>Aggiorna</button>
            </div>
            <div className="col-md-6">
              {/* <h3>Giorni speciali</h3> */}
            </div>
          </div>
        )
      }
    </div>
  );
}
