import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import IntlTelInput from 'react-intl-tel-input';
import { RequestFilters } from '../../../../app-lib/sdk/interfaces/RequestFilters';
import * as Yup from 'yup';
import Autocomplete from '../atoms/autocomplete';
import { ResponseResults } from '../../../../app-lib/sdk/interfaces/ResponseResults';
import sdk from '../../../../app-lib/sdk/sdk.service';
import { Utils } from '../../../../lib/utils/core';

import 'react-intl-tel-input/dist/main.css';

const Error = (error: any): JSX.Element => (
  <span className="text-danger">{error}</span>
);

const today = (new Date()).toISOString().split('T')[0];

export const ReservationForm: React.FC<any> = ({initialValues, type = 'create', customers, allergens, onSubmit}: { initialValues: RequestFilters.ReservationCreate & any; type: 'create' | 'update' | 'walk-in'; customers: ResponseResults.Customer[], allergens: ResponseResults.Allergen[], onSubmit: () => void}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [availabilities, setAvailabilities] = useState<ResponseResults.ReservationsAvailabilities[]>([]);
  const [timeSlots, setTimeSlots] = useState<ResponseResults.SlotDefinition[]>();
  const [loading, setLoading] = useState(true);

  const formRef = useRef();

  const isUpdateWalkIn = type === 'update' && initialValues.first_name == null && initialValues.last_name == null && initialValues.phone == null && initialValues.customer_id == null;

  let schemaShape = {
    guests: Yup.number().min(1).required('Required'),
    start: Yup.number().min(0).required('Required'),
    date: Yup.date().required('Required').min(new Date(Date.now() -86400000), 'La data non può essere nel passato'),
    allergens: Yup.array(),
  };

  if (
    type !== 'walk-in' && !isUpdateWalkIn
  ) {
    schemaShape = {
      ...schemaShape,
      customer_id: Yup.number()
        .when(['first_name', 'last_name', 'phone'], {
          is: (v) => {
            return v == '' || v == null
          },
          then: (schema: any) => schema.min(1, 'Selezionare un cliente').required('Required'),
        }),
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      phone: Yup.string().test('intl-tel', 'The number is incorrect', (v) => {
        return (new IntlTelInput({ defaultValue: v })).isValidNumber(v);
      }),
      email: Yup.string().nullable(true),
    } as any;
  }

  const ReservationSchema = Yup.object().shape(schemaShape);

  useEffect(() => {
    setLoading(true);
    sdk.getReservationsAvailabilities(initialValues.date || today, initialValues.guests).then((a) => {
      setAvailabilities(Object.values(a.results).flatMap(a => ([...a as any])))
    });

    sdk.getSlotDefinitions().then((a) => {
      setTimeSlots(a.results)
      setLoading(false);
    });
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ReservationSchema}
      innerRef={formRef as any}
      onSubmit={(values: any /*RequestFilters.ReservationCreate*/): void => {
        if (type === 'create') {
          if (values.customer_id === 0) {
            const customer = customers.find(c => {
              return c.first_name.toLowerCase() === values.first_name.toLowerCase()
                && c.last_name.toLowerCase() === values.last_name.toLowerCase()
                && c.phone.toLowerCase() === values.phone.toLowerCase()
            });

            if (customer) {
              sdk.createReservation({...values, customer_id: customer.id})
                .then(onSubmit);
            } else {
              const d = { first_name: values.first_name, last_name: values.last_name, phone: values.phone, prefered_language: values.prefered_language };
              if (values.email) {
                (d as any).email = values.email
              }
              sdk.createCustomer(d)
                .then(({results: { id }}) => {
                  sdk.createReservation({...values, customer_id: id})
                    .then(onSubmit);
                })
                .catch(() => alert('Non è stato possibile registrare l\'utenza'))
            }
          } else {
            sdk.createReservation({...values})
              .then(onSubmit)
              .catch(() => alert('Non è stato possibile salvare la prenotazione'))
          }
        }

        if (type === 'update') {
          sdk.updateReservation({...values})
            .then(onSubmit)
            .catch(() => alert('Non è stato possibile aggiornare la prenotazione'))
        }

        if (type === 'walk-in') {
          const {customer_id, ...data} = values;
          sdk.createReservation(data)
            .then(onSubmit)
            .catch(() => alert('Non è stato possibile registrare l\'utenza'))
        }
      }}
    >
      {({ values, setFieldValue, handleChange }): JSX.Element => (
        <Form onKeyDown={(e) => {
          if ((e.charCode || e.keyCode) === 13) {
            e.preventDefault();
          }
        }}>
          <div>
            {(type !== 'walk-in' && !isUpdateWalkIn) && (
              <CustomerSubform
                canSearch={type === 'create'}
                customers={customers}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            )}

            <h4>Dati prenotazione</h4>

            <div className='row'>
              <div className='col-6'>
                <div className="form-group">
                  <label className='mr-2'>Allergie:</label>
                  <MultiSelect
                    selected={values.allergies}
                    options={allergens.map(a => ({label: a.name, value: ''+a.id}))}
                    onChange={(values) => setFieldValue('allergies', values)}
                  />
                  <ErrorMessage name="allergies" render={Error} />
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <label className='mr-2'>Note:</label>
                  <Field
                    type="text"
                    as="textarea"
                    name="notes"
                    className="form-control"
                    placeholder="Note"
                  />
                  <ErrorMessage name="notes" render={Error} />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <div className="form-group">
                  <label className='mr-2'>Ospiti:</label>
                  <Field
                    type="number"
                    name="guests"
                    className="form-control"
                    placeholder="Ospiti"
                    onChange={(e: any) => {
                      handleChange(e);
                      sdk.getReservationsAvailabilities(values.date, e.target.value).then((a) => {
                        setAvailabilities(Object.values(a.results).flatMap(a => ([...a as any])))
                      });
                    }}
                  />
                  <ErrorMessage name="guests" render={Error} />
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <label className='mr-2'>Data:</label>
                  <Field
                    type="date"
                    name="date"
                    className="form-control"
                    placeholder="Data"
                    disabled={type == 'walk-in' || (type == 'update' && ((initialValues.reservation.state == 'confirmed' && initialValues.reservation.tables && initialValues.reservation.tables.length > 0) || initialValues.reservation.state == 'arrived'))}
                    onChange={(e: any) => {
                      handleChange(e);
                      sdk.getReservationsAvailabilities(e.target.value, values.guests).then((a) => {
                        setAvailabilities(Object.values(a.results).flatMap(a => ([...a as any])))
                      });
                    }}
                  />
                  <ErrorMessage name="date" render={Error} />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className='mr-2'>Orario prenotazione:</label>
              {loading ?
                <>Loading</> : (
                <>
                  {(!loading && timeSlots && timeSlots.length > 0) ? (
                    <RadioButtons
                      keyString="times"
                      disabled={type == 'update' && ((initialValues.reservation.state == 'confirmed' && initialValues.reservation.tables && initialValues.reservation.tables.length > 0) || initialValues.reservation.state == 'arrived')}
                      options={[
                        // @ts-ignore
                        ...new Set(
                          timeSlots.filter(t => {
                            return t.day_of_week == Utils.jsDayFormatToInternational(new Date(values.date))
                          }).map(t => t.start)
                        )
                      ].map(t => ({
                        label: Utils.timeFormatterMtHM(t),
                        value: t,
                        available: availabilities?.find(a => {
                          // console.log(a.start, t, a.start == t);
                          return a.start == t;
                        }),
                        classList: availabilities?.find(a => {
                          // console.log(a.start, t, a.start == t);
                          return a.start == t;
                        }) ? 'bg-success' : 'bg-danger',
                      }))}
                      selectedValue={values.start}
                      onChange={(value: number) => {
                        setFieldValue('start', value)
                        // TODO: fix this (remove hardcoded 120)
                        setFieldValue('end', value + 120)
                      }}
                    />
                  ) : <>Nessun orario disponibile</>}
                </>
              )}
              {/* <Field
                type="text"
                name="start"
                className="form-control"
                placeholder="Start"
              /> */}
              <ErrorMessage name="start" render={Error} />
            </div>

            {/* <div className="form-group">
              <label className='mr-2'>Tavoli:</label>
            </div> */}

            <button
              className="btn btn-primary btn-user btn-block"
              type="submit"
            >
              {type === 'update' ? 'Aggiorna prenotazione' : 'Registra prenotazione'}
            </button>

            {/* <hr />
            <a href="index.html" className="btn btn-google btn-user btn-block">
              <i className="fab fa-google fa-fw"></i> Login with Google
            </a>
            <a href="index.html" className="btn btn-facebook btn-user btn-block">
              <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
            </a> */}


          </div>

          {errors &&
            errors.map(
              (el: string, key: number): JSX.Element => (
                <p className="text-danger mt-2 mb-0" key={'error-' + key}>
                  {el}
                </p>
              ),
            )}
        </Form>
      )}
    </Formik>
  );
};

type MultiSelectOption = {label: string, value: string}
const MultiSelect = (props: {
  selected: string[],
  options: MultiSelectOption[],
  onChange: (values: string[]) => void;
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(props.selected.map(id => ''+id));
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOptionClick = (option: {label: string, value: string}) => {
    let newSelectedValues = [...selectedValues];
    if (selectedValues.includes(option.value)) {
      newSelectedValues = newSelectedValues.filter((value) => value !== option.value);
    } else {
      newSelectedValues.push(option.value);
    }
    setSelectedValues(newSelectedValues);
    props.onChange(newSelectedValues);
  };

  const filteredOptions = props.options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const closeDropdown = useCallback(() => {
    setShowDropdown(false);
    setSearchTerm('');
  }, [showDropdown])

  const toggleDropdown = useCallback(() => {
    if (showDropdown) {
      closeDropdown();
    } else {
      setShowDropdown(true);
    }
  }, [showDropdown])

  const handleClickOutOfDropdown = (e: any) => {
    if (e.target.closest('.multiselect') == null) {
      closeDropdown();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutOfDropdown);

    return () => {
      window.removeEventListener('click', handleClickOutOfDropdown);
    }
  }, [])

  return (
    <div className="position-relative multiselect" style={{zIndex: 9999}}>
      <div className="form-outline position-relative">
        <input
          className="form-control bg-white"
          type="text"
          readOnly={true}
          placeholder="Seleziona allergie"
          onClick={() => toggleDropdown()}
          value={props.options.filter((o) => selectedValues.includes(o.value)).map(o => o.label).join(', ')}
        />
        <span className="select-arrow position-absolute" style={{
          width: '0',
          height: '0',
          border: '4px solid transparent',
          borderTop: '4px solid',
          borderRight: '4px solid',
          margin: '15px',
          zIndex: 99,
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          transform: 'rotate(135deg)'
        }}></span>
      </div>
      {showDropdown && (
        <>
          <input type="text" placeholder="Cerca..." className="form-control" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />
          <div className="position-absolute overflow-auto" style={{ top: "calc(100% + 10px)", left: 0, right: 0, maxHeight: '150px' }}>
            <ul className="list-group">
              {filteredOptions.map((option, index) => (
                <li key={`multiselect-${index}`} className="list-group-item d-flex justify-content-between align-items-center" onClick={() => handleOptionClick(option)}>
                  {option.label}
                  {selectedValues.includes(option.value) && <span className="badge badge-primary badge-pill">Selezionato</span>}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

const RadioButtons = ({ keyString, options, selectedValue, onChange, disabled }: any) => {
  const [value, setValue] = useState(selectedValue);

  const handleChange = (newValue: any) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className='d-inline-block'>
      {disabled ?
        <p>{Utils.timeFormatterMtHM(value)}</p>
        : (
        <>
          {options.map((option: any) => (
            <a
              key={keyString + option.value}
              // disabled={option.disabled}
              className={`text-dark mr-2 mb-2 btn ${value === option.value ? 'btn-primary' : option.classList}`}
              onClick={() => {
                if (!option.available) {
                  if (window.confirm('Sei sicuro di voler selezionare questo orario? (possibile overbooking/prenotazione a fine giornata)')) {
                    handleChange(option.value)
                  }
                } else {
                  handleChange(option.value)
                }
              }}
            >
              {option.label}
            </a>
          ))}
        </>
      )}
    </div>
  );
};

const CustomerSubform = (
  {customers, values, handleChange, setFieldValue, canSearch}:
  {customers: ResponseResults.Customer[], values: any, handleChange: any, setFieldValue: any, canSearch: boolean}
) => {
  const [showFindCustomer, setShowFindCustomer] = useState(canSearch);
  const [filteredCustomers, setFilteredCustomers] = useState<ResponseResults.Customer[]>([]);

  const initSuggestion = {
    last_name: '',
    first_name: '',
    email: '',
    phone: '',
  };
  const [suggestionData, setSuggestionData] = useState(initSuggestion);

  const handleCustomerChange = (e: any) => {
    handleChange(e);
  }

  return (
    <>
      <div className='row'>
        <div className='col-6 col-md-8'>
          <h4>
            Cliente
            {canSearch && (
              <button className="btn btn-primary ml-2" type="button" onClick={() => {
                setShowFindCustomer(true)
              }}>
                <i className="fas fa-search fa-sm"></i>
              </button>
            )}
          </h4>
        </div>
        <div className='col-md-2 col-3'>
          <select className="pl-2 py-2 rounded w-100" value={values['provenance']} onChange={(e) => setFieldValue('provenance', e.target.value)}>
            <option value="phone">Telefono</option>
            <option value="thefork">The Fork</option>
          </select>
        </div>
        <div className='col-md-2 col-3'>
          <Field
            type="text"
            autoComplete="do-not-autofill"
            className="form-control"
            placeholder="ext_id"
            value={values['external_id']}
            onChange={(e: any) => setFieldValue('external_id', e.target.value)}
          />
        </div>
      </div>
      <ErrorMessage name="customer_id" render={Error} />

      {showFindCustomer && (
        <div className="form-group">
          <Autocomplete
            placeholder='Cerca cliente'
            suggestions={filteredCustomers.map(f => `${f.user_id ? '<u>' : ''}${f.first_name} ${f.last_name} - ${f.phone} - ${f.email} ~${f.id}${f.user_id ? '</u>' : ''}`)}
            onTypeComplete={(query: string) => {
              setFilteredCustomers(
                customers
                  .filter(c => (`${c.first_name} ${c.last_name}`).toLowerCase().includes(query.toLowerCase()))
                  .sort((a,b) => (b as any).reservations.length - (a as any).reservations.length) // top customers first
              )
            }}
            onNewValue={(value) => {
              console.log('new', value);

              setShowFindCustomer(false);

              setFieldValue('customer_id', 0);
              setFieldValue('last_name', '');
              setFieldValue('first_name', '');
              setFieldValue('phone', '');
              setFieldValue('email', '');
            }}
            onSuggestionSelect={async (value) => {
              const id = value.split('~')[1];
              setFieldValue('customer_id', id);

              const data = {
                first_name: customers.find(c => c.id == Number(id))?.first_name || '',
                last_name: customers.find(c => c.id == Number(id))?.last_name || '',
                email: customers.find(c => c.id == Number(id))?.email || '',
                phone: customers.find(c => c.id == Number(id))?.phone || '',
                prefered_language: customers.find(c => c.id == Number(id))?.prefered_language || '',
              }

              Object.entries(data)
                .forEach(([field, value]) => setFieldValue(field, value));

              setSuggestionData(data)
              setShowFindCustomer(false);
            }}
          />
        </div>
      )}

      {!showFindCustomer && (
        <>
          <div className='row'>
            <div className='col-6'>
              <div className="form-group">
                <label>Cognome</label>
                <Field
                  type="text"
                  name="last_name"
                  autoComplete="do-not-autofill"
                  className="form-control"
                  placeholder="Cognome"
                  disabled={values.customer_id !== 0}
                  onChange={handleCustomerChange}
                />
                <ErrorMessage name="last_name" render={Error} />
              </div>
            </div>
            <div className="col-6">
              <label>Nome</label>
              <div className="form-group">
                <Field
                  type="text"
                  name="first_name"
                  autoComplete="do-not-autofill"
                  className="form-control"
                  placeholder="Nome"
                  disabled={values.customer_id !== 0}
                  onChange={handleCustomerChange}
                />
                <ErrorMessage name="first_name" render={Error} />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <div className="form-group">
                <label>Email</label>
                <Field
                  type="email"
                  name="email"
                  autoComplete="do-not-autofill"
                  className="form-control"
                  placeholder="Email"
                  disabled={values.customer_id !== 0}
                  onChange={handleCustomerChange}
                />
                <ErrorMessage name="email" render={Error} />
              </div>
            </div>
            <div className='col-6'>
              <div className="form-group">
                <label>Telefono</label>
                <IntlTelInput
                  inputClassName="w-100 mt-4 mr-4 px-6 py-2 border rounded"
                  containerClassName="intl-tel-input"
                  fieldName={'phone'}
                  placeholder="Telefono"
                  defaultValue={values.phone}
                  autoComplete="do-not-autofill"
                  disabled={values.customer_id !== 0}
                  style={values.customer_id !== 0 ? {backgroundColor: '#eaecf4'} : {}}
                  nationalMode={false}
                  preferredCountries={['gb', 'it']}
                  onSelectFlag={(
                    currentNumber,
                    selectedCountryData,
                    fullNumber,
                    isValid,
                  ) => {
                    // @ts-ignore
                    handleChange('phone')(fullNumber);
                  }}
                  onPhoneNumberChange={(
                    isValid,
                    value,
                    selectedCountryData,
                    fullNumber
                  ) => {
                    // @ts-ignore
                    handleChange('phone')(fullNumber);
                  }}
                />
                {/* <Field
                  type="tel"
                  name="phone"
                  autoComplete="do-not-autofill"
                  className="form-control"
                  placeholder="Telefono"
                  disabled={values.customer_id !== 0}
                  onChange={handleCustomerChange}
                /> */}
                <ErrorMessage name="phone" render={Error} />
              </div>
            </div>
            <div className='col-6'>
              <div className="form-group">
                <label>Lingua utente</label>
                <select
                  className="pl-2 py-2 rounded w-100 bg-white"
                  value={values['prefered_language']}
                  onChange={(e) => setFieldValue('prefered_language', e.target.value)}
                >
                  <option value="it">it</option>
                  <option value="en">en</option>
                </select>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
