import React, { useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import { GenericObject } from '../../../lib/utils/models';

import { Utils } from '../../../lib/utils/core';
import CopyToClipboard from '../components/molecules/copy-to-clipboard';
import { JWT } from '../../../lib/utils/jwt';
import { Modal } from '../components/molecules/modal';

export default function SurveySatisfaction(props: any): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [surveyResponses, setSurveyResponses] = useState<{
    id: string,
    'score.satisfaction': number,
    'score.clean': number;
    'score.service': number,
    'totalScore': number,
    customer: ResponseResults.Customer,
    reservation: ResponseResults.Reservation,
    created_at: Date;
  }[]>([]);
  const [customers, setCustomers] = useState<ResponseResults.Customer[]>([]);
  const [reservations, setReservations] = useState<ResponseResults.Reservation[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<GenericObject | false>(false);

  const fetchData = async () => {
    try {
      const [customersResponse, reservationsResponse] = await Promise.all([
        sdk.getCustomers(),
        sdk.getReservations()
      ]);

      const customers = customersResponse.results;
      const reservations = reservationsResponse.results;

      const satisfactionResponsesResponse = await sdk.getSatisfactionSurveyResponses();
      const satisfactionResponses = satisfactionResponsesResponse.results;

      const data = satisfactionResponses.map(result => {
        const tokenData: { customer_id: number, reservation_id: string } = JWT.decodeToken(result.token);

        const customer = customers.find(({ id }) => id === tokenData.customer_id);
        const reservation = reservations.find(({ id }) => id === tokenData.reservation_id);

        console.log(customers, reservations, tokenData);

        return {
          ...result.json,
          id: result.id,
          customer,
          reservation,
          created_at: result.created_at
        };
      });

      setSurveyResponses(data as any);
    } catch (e) {
      setErrors(Utils.formatErrors(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Customers Satisfaction Survey</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-10 offset-md-1 overflow-auto">
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Data sondaggio</th>
                  <th>Cliente</th>
                  <th>Data prenotazione</th>
                  <th>Risultato</th>
                  <th>Azioni</th>
                </tr>
              </thead>
              <tbody>
                {surveyResponses
                  .sort((c1, c2) => c1.created_at > c2.created_at ? 1 : -1)
                  .map(surveyResponse => (
                    <tr key={`customer-${surveyResponse.id}`} className={`${surveyResponse.totalScore > 12 ? '' : 'bg-warning'}`}>
                      <td>{surveyResponse.created_at}</td>
                      <td>
                        <CopyToClipboard title={`copy phone (${surveyResponse.customer.phone})`} data={surveyResponse.customer.phone} />
                        {surveyResponse.customer.last_name} {surveyResponse.customer.first_name}
                      </td>
                      <td>{surveyResponse.reservation.date}</td>
                      <td>{surveyResponse.totalScore} / 20</td>
                      <td>
                        <a href="#" onClick={() => setOpenModal(surveyResponse)}>Dettagli</a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>

            {openModal && (
                <Modal
                  onClose={() => setOpenModal(false)}
                >
                  <ul>
                    <li><strong>Sondaggio del:</strong> {openModal.created_at}</li>
                    <li><strong>Cliente:</strong> {openModal.customer.last_name} {openModal.customer.first_name}</li>
                    {Object.entries(openModal)
                      .filter(([key]) => key.startsWith('score.') || key.startsWith('text.'))
                      .map(([key, value]) => (
                        <li key={key}><strong>{key.replace(/(score|text)\./g, '')}:</strong> {value}</li>
                      ))
                    }
                  </ul>
                </Modal>
              )}
          </div>
        )
      }
    </div>
  );
}
