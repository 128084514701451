const isString = (value: any): boolean => typeof value === 'string';

const isBoolean = (value: any): boolean => typeof value === 'boolean';

const isDefined = (value: any): boolean =>
  value !== undefined && value !== null;

const isNotDefined = (value: any): boolean =>
  value === undefined || value === null;

const isFunction = (value: any): boolean => typeof value === 'function';

const isNumber = (value: any): boolean => typeof value === 'number';

const isObject = (value: any): boolean => typeof value === 'object';

const isArray = (value: any): boolean => Array.isArray(value);

const roundDigits = (value: number, digits: number = 2) => {
  const p = Math.pow(10, digits);
  return Math.round(value * p) / p
}

const isEmptyString = (value: any): boolean =>
  typeof value !== 'string' || value.length === 0;

const isEmpty = (value: any): boolean => {
  if (isNotDefined(value)) {
    return true;
  }
  switch (typeof value) {
    case 'string': {
      value = String(value);
      return isEmptyString(value);
    }
    case 'number':
      return false;
    default:
      return Object.keys(value).length === 0;
  }
};

const formatErrors = (response: any): string[] => {
  const messages = [];
  const cause = JSON.parse(response.cause);
  // TODO: fix: questo funziona se gli errori sono dentro cause
  // l'errore principale dovrebbe stare lì,
  // invece i vari errori dovrebbero stare su results
  if (isDefined(cause.message)) {
    if (isString(cause.message)) {
      messages.push(`Error ${cause.status}: ${cause.message}`);
    }
    if (isArray(cause.results)) {
      for (let i = 0; i < cause.results.length; ++i) {
        const data = cause.results[i];
        if (isString(data)) {
          messages.push(data);
        // } else if (isDefined(data.constraints)) {
        //   const keys = Object.keys(data.constraints);
        //   for (let j = 0; j < keys.length; j++) {
        //     messages.push(data.constraints[keys[j]]);
        //   }
        // }
        } else if (isObject(data) && isDefined(data.message)) {
          messages.push(data.message)
        }
      }
    }
  }

  return messages;
};

type EnumType = { [s: number]: string };
type SameKeyValue<T> = { [K in keyof T]: string };
function keyValueFromEnum<T>(enumerator: EnumType): SameKeyValue<T> {
  for (const key in enumerator) {
    if (enumerator.hasOwnProperty(key)) {
      enumerator[key] = key;
    }
  }
  return enumerator as unknown as SameKeyValue<T>;
}

const replaceAll = (str: string, find: string, replace: string): string => {
  return str.replace(new RegExp(find, 'g'), replace);
};

const timeFormatterMtHM = (n: number) => {
  var num = n;
  var hours = (num / 60);
  var rhours: string|number = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes: string|number = Math.round(minutes);
  rhours = rhours < 10 ? '0'+rhours : ''+rhours;
  rminutes = rminutes < 10 ? '0'+rminutes : ''+rminutes;
  return rhours + ":" + rminutes;
}

// Accepts the array and key
const groupBy = (array: any[], key: string) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const jsDayFormatToInternational = (date: Date) => {
  return ((date).getDay() + 6) % 7 + 1;
}

const isBetween = (x: number, min: number, max: number) => {
  return x >= min && x <= max;
}

export const Utils = {
  isString,
  isBoolean,
  isDefined,
  isNotDefined,
  isFunction,
  isNumber,
  isObject,
  isArray,
  isEmptyString,
  isEmpty,
  formatErrors,
  keyValueFromEnum,
  replaceAll,
  timeFormatterMtHM,
  groupBy,
  jsDayFormatToInternational,
  roundDigits,
  isBetween,
};
