import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Autocomplete from '../atoms/autocomplete';

const Item = ({model, link, onDelete, isOrderMode, onOrderPrev, onOrderNext}: any) => {
  return (
    <Link to={link} style={{display: 'block'}}>
      {isOrderMode && (
        <div className="float-left mr-3">
          {onOrderPrev && (
            <span className="p-1" onClick={(e) => {
              e.preventDefault();
              onOrderPrev();
            }}>
              {'↑'}
            </span>
          )}
          {onOrderNext && (
            <span className="p-1" onClick={(e) => {
              e.preventDefault();
              onOrderNext();
            }}>
              {'↓'}
            </span>
          )}
        </div>
      )}

      <span>{model.name}</span>
      <div className="float-right">
        {onDelete && (
          <span onClick={(e) => {
            e.preventDefault();
            onDelete(model)
          }}>
            remove
          </span>
        )}
        <span className="ml-3">&gt;</span>
      </div>
    </Link>
  )
}

export interface ListArgs<T = any> {
  model: T[];
  onDelete?: (item: T) => void;
  onCreateNew?: (value: string) => void;
  getOrder?: (model: T) => number;
  setOrder?: (model: T, value: number) => void;
  onOrder?: (model: T[]) => void;
  generateListLink: (item: T) => string;
  addPlaceholder: string;
  title: string;
  autocomplete?: typeof Autocomplete.defaultProps;
}

const List: React.FC<ListArgs> = ({model, onDelete, onCreateNew, onOrder, getOrder, setOrder, generateListLink, addPlaceholder, title, autocomplete}) => {

  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOrderMode, setOrderMode] = useState<boolean>(false);

  // HACK ...
  model = model.map(m => ({
    ...m,
    order: getOrder ? getOrder(m) : m.id,
  }))

  const [orderModel, setOrderModel] = useState<any[]>(model);

  const createNew = useCallback(
    (e) => e.key === 'Enter' && onCreateNew && (
      onCreateNew(value)
    ),
    [value]
  );

  return (
    <>
      {title && <p className="paragraph mt-3 mb-0 text-dark text-uppercase font-weight-bold">{title}</p>}

      <div>
        <input
          type="text"
          className="form-control w-50 ml-auto"
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>

      {onOrder && (
        <div>
          <button className='btn btn-primary' onClick={() => {
            setOrderMode(true);
          }}>
            riordina
          </button>
        </div>
      )}

      {(isOrderMode ? orderModel : model).
        sort((a,b) => a.order - b.order).
        filter((item) => isOrderMode || item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1).
        map((item: any, index: number) => (
          <Item
            isOrderMode={isOrderMode}
            onOrderNext={setOrder && index < orderModel.length - 1 ? () => {
              const current = orderModel[index];
              const next = orderModel[index + 1];

              // console.log('current, next', current.name, next.name, current.order, next.order);
              const tmp = current.order;
              // TODO: remove this horrible HACK ç.ç
              setOrder(current, next.order);
              current.order = next.order;
              setOrder(next, tmp);
              next.order = tmp;
              // console.log(current.order, next.order);
              setOrderModel([...orderModel]);
            } : undefined}
            onOrderPrev={setOrder && index > 0 ? () => {
              const current = orderModel[index];
              const prev = orderModel[index - 1];

              // console.log('current, prev', current.name, prev.name, current.order, prev.order);
              const tmp = current.order;
              // TODO: remove this horrible HACK ç.ç
              setOrder(current, prev.order);
              current.order = prev.order;
              setOrder(prev, tmp);
              prev.order = tmp;
              // console.log(current.order, prev.order);
              setOrderModel([...orderModel]);
            } : undefined}
            key={index}
            model={item}
            link={generateListLink(item)}
            onDelete={onDelete}
          />
        ))}

      {onOrder && isOrderMode && (
        <button className='btn btn-success' onClick={() => {
          onOrder(orderModel);
        }}>
          Conferma ordinamento
        </button>
      )}

      {!autocomplete ? (
        <input
          type="text"
          className="form-control mt-3"
          value={value}
          placeholder={addPlaceholder}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={createNew}
        />
      ) : (
        <Autocomplete {...autocomplete as any} />
      )}
    </>
  );
}

export default List;
