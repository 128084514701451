import { BaseUrl } from '../lib/sdk/interfaces/RequestHandler';
import { AppConstants } from '../lib/constants';

export type Environment = 'production' | 'stage' | 'development';

export const Constants: AppConstants<BaseUrl<Environment>, Environment> = {
  APP: {
    NAME: process.env.REACT_APP_NAME as string,
    FAVICON: 'https://tuskcode.it/favicon.ico',
    VERSION: '1.0.0',
    DEBUG: true,
  },
  API: {
    VERSION: process.env.REACT_APP_API_VERSION as string,
    BASEURL: {
      development: process.env.REACT_APP_API_BASEURL_DEV as string,
      stage: process.env.REACT_APP_API_BASEURL_STAGE as string,
      production: window.location.origin.replace('manager', 'api') + '/',
    },
    ENV: process.env.REACT_APP_API_ENV as Environment,
  },
  URI: {
    TERMS_URL: {
      'it': 'https://tuskcode.it/it/termini-e-condizioni/',
      'en': 'https://tuskcode.it/en/terms-and-conditions/'
    },
    PRIVACY_URL: {
      'it': 'https://tuskcode.it/it/privacy',
      'en': 'https://tuskcode.it/en/privacy'
    },
    // FACEBOOK_PAGE: 'fb://page/102685794552452/',
    FACEBOOK_PAGE: 'https://www.facebook.com/TuskCode/',
    EMAIL_TO: 'mailto:info@tuskcode.it',
    EMAIL_SUBJECT: 'TUSKCODE SUPPORT',
    WEBSITE: 'https://tuskcode.it',
  }
}
