import React, { useEffect, useMemo, useState } from 'react';

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
// import appRoutes from "./appRoutes"

import Analytics from './theme/views/analytics';

import Menus from './theme/views/menus';
import Menu from './theme/views/menu';
import MenuSection from './theme/views/menu-section';
import Dish from './theme/views/dish';
import NotFound from './theme/views/not-found';
import AppTemplate from './theme/components/templates/app.template';
import Login from './theme/views/login';
import Ingredients from './theme/views/ingredients';
import Ingredient from './theme/views/ingredient';

import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { getStore } from './store';
import { AppState } from './store/reducers';
import { startupSuccess } from './store/reducers/general/actions';
import { Constants } from '../app-lib/constants';
import Dishes from './theme/views/dishes';
import Reservations from './theme/views/reservations';
import Location from './theme/views/location';
import Rooms from './theme/views/rooms';
import Room from './theme/views/room';
import Table from './theme/views/table';
import OpeningTimes from './theme/views/opening-times';
import OpeningTimesSpecial from './theme/views/opening-times-special';
import Customers from './theme/views/customers';
import QRCode from './theme/views/qrcode';
import SurveySatisfaction from './theme/views/survey-satisfaction';
import { DateTime } from 'luxon';

const { store, persistor } = getStore();

// import routerService from '../../services/router.service';
// import appRoutes from '../../appRoutes';
// import { IRoute } from '../../models/route';
// import NotFound from '../../views/not-found';

// // type RouteProps = { component: React.FC; subRoutes?: any } & RouteComponentProps;
// // const Route: React.FC<RouteProps> = ({ component: Component, subRoutes, ...rest }) => {
// //   console.log(subRoutes)
// //   return (
// //     <Component {...rest}>
// //       {subRoutes && subRoutes.map((child: any) => {
// //         console.log(child)
// //         return <Route {...child} />
// //       })}
// //     </Component>
// //   )
// // };

// function App() {
//   const routes = routerService.loadRoutes(appRoutes);

//   const tmp = ({subRoutes, component: Component, ...route}: IRoute<React.FC>, idx: number) => {
//     return (
//       <Component key={route.path} {...route}>
//         {subRoutes && subRoutes.map(tmp)}
//       </Component>
//     );
//   };

//   // const tmp = ([path, {subRoutes, component: Component, ...route}]: [string, IRoute<React.FC>]) => {
//   //   return (
//   //     <Component {...route}>
//   //       {/* {subRoutes && subRoutes.map(tmp)} */}
//   //     </Component>
//   //   );
//   // };

//   return (
//     <Router>
//       <NotFound key="def" default />

//       {/* <NotFound default /> */}
//       {/* {true && (function() {
//         return <NotFound default />
//       })()} */}
//       {routes.map(tmp)}
//     </Router>
//   );
// }

// export default App;

function PrivateRoute(props: any) {
  const auth = useSelector((state: AppState) => state.auth);
  // const user = useSelector((state: AppState) => state.user.user);

  if (auth.isAuthenticated) {
    // if (role && user && role !== user.role) {
    //   return <Redirect from="" to="/" />;
    // }
    return <Route {...props} />;
  }

  return <Redirect from="" to="/login" />;
}

const Wrapper: React.FC = () => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    document.title = Constants.APP.NAME;
    (document.getElementById("favicon") as any).href = Constants.APP.FAVICON;
    dispatch(startupSuccess());
    setReady(true);
  }, [dispatch]);

  const user = useSelector((state: AppState) => state.user);
  const plan = useMemo(() => user?.user?.plan, [user]);

  return (
    <>
      {ready ? (
        <BrowserRouter>
          <Switch>
            {/* TODO: impedire l'accesso all'utente loggato */}
            <Route exact path={'/login'}>
              <Login />
            </Route>

            <AppTemplate>
              <>
                {(plan && plan.expiration_date && DateTime.fromISO(plan.expiration_date).diff(DateTime.local(), 'days').days <= 30) && (
                  <div className="container">
                    <div className="alert alert-danger" role="alert">
                    <b>Attenzione</b> - la tua licenza è in scadenza il {plan.expiration_date}. Ti invitiamo a rinnovarla al più presto per evitare interruzioni del servizio.
                    <hr />
                    <b>Warning</b> - Your license expires on {plan.expiration_date}. We invite you to renew it as soon as possible to avoid service interruptions.
                    </div>
                  </div>
                )}

                <Switch>
                  <Redirect from="/" to="/situation" exact />
                  <PrivateRoute exact path={'/situation'}>
                    <Analytics />
                  </PrivateRoute>
                  {/* <PrivateRoute exact path={'/home'}>
                    <Analytics />
                  </PrivateRoute> */}
                  <PrivateRoute exact path={'/menus'}>
                    <Menus />
                  </PrivateRoute>
                  {/* <PrivateRoute exact path={'/menus/create'}>
                    <MenusCreate />
                  </PrivateRoute> */}
                  <PrivateRoute exact path={'/menus/:menuId'}>
                    <Menu />
                  </PrivateRoute>
                  {/* <PrivateRoute exact path={'/menus/:menuId/section'}>
                    <Redirect from="/section" to="/menus/:menuId" />
                  </PrivateRoute> */}

                  <PrivateRoute exact path={'/menus/:menuId/section/:sectionId'}>
                    <MenuSection />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/menus/:menuId/section/:sectionId/dish/:dishId'}>
                    <Dish />
                  </PrivateRoute>

                  <Redirect from="/menus/:menuId/section" to="/menus/:menuId" />
                  <Redirect from="/menus/:menuId/section/:sectionId/dish" to="/menus/:menuId/section/:sectionId" />

                  <PrivateRoute exact path={'/dishes'}>
                    <Dishes />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/dishes/:dishId'}>
                    <Dish />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/qrcode'}>
                    <QRCode />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/ingredients'}>
                    <Ingredients />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/ingredients/:ingredientId'}>
                    <Ingredient />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/reservations'}>
                    <Reservations />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/survey-satisfaction'}>
                    <SurveySatisfaction />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/customers'}>
                    <Customers />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/settings'}>
                    <Location />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/rooms'}>
                    <Rooms />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/rooms/:roomId'}>
                    <Room />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/rooms/:roomId/tables/:tableId'}>
                    <Table />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/opening-times'}>
                    <OpeningTimes />
                  </PrivateRoute>

                  <PrivateRoute exact path={'/opening-times-special'}>
                    <OpeningTimesSpecial />
                  </PrivateRoute>

                  <PrivateRoute path="*">
                    <NotFound />
                  </PrivateRoute>
                </Switch>
              </>
            </AppTemplate>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      ) : <p>loading</p>}
    </>
  );
};

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Wrapper />
    </PersistGate>
  </Provider>
)

export default App;
