import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory, useParams } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import List, { ListArgs } from '../components/organisms/list.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { UpdateForm } from '../components/organisms/updateForm.component';
import { Utils } from '../../../lib/utils/core';

export default function MenuSection(props: any): JSX.Element {
  const history = useHistory();

  const params = useParams<{menuId: string, sectionId: string}>()

  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState<ResponseResults.MenuSection>();
  const [errors, setErrors] = useState<string[]>([]);
  const formRef = useRef();

  const [dishes, setDishes] = useState<ResponseResults.Dish[]>([]);
  const [filteredDishes, setFilteredDishes] = useState<ResponseResults.Dish[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getMenuSection(Number(params.sectionId))
      .then(res => setSection(res.results))
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
    sdk.getDishes()
      .then(res => setDishes(res.results))
  }, []);

  useEffect(() => {
    loadData()
  }, []);

  const ListDish = List as React.FC<ListArgs<ResponseResults.Dish>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Sezione del menu</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            <UpdateForm
              formRef={formRef}
              model={section}
              loading={loading}
              fields={[
                // {
                //   name: 'is_active',
                //   type: 'switch',
                //   placeholder: 'Is active',
                // },
                {
                  name: 'name',
                  type: 'text',
                  placeholder: 'Enter menu section name...',
                },
                {
                  name: 'description',
                  type: 'text',
                  placeholder: 'Enter menu section description...',
                },
                {
                  name: 'image',
                  type: 'image',
                  placeholder: 'Add image...',
                },
              ]}
              onSubmit={(values: RequestFilters.MenuSectionUpdate) => {
                setLoading(true);
                sdk.updateMenuSection(values)
                  .then(loadData)
                  .catch(e => {setErrors(Utils.formatErrors(e)); setLoading(false)})
              }}
            />

            <button className="btn btn-danger btn-block mt-2" onClick={() => {
              if (window.confirm('Vuoi eliminare definitivamente la sezione ' + section?.name + '?')) {
                setLoading(true);
                sdk.deleteMenuSection((section as ResponseResults.MenuSection).id)
                  .then(() => {
                    history.replace(
                      history.location.pathname.substring(0, history.location.pathname.indexOf('/section/'))
                    );
                  })
                  .catch(e => setErrors(Utils.formatErrors(e)))
                  .finally(() => setLoading(false))
              }
            }}>Delete</button>

            <ListDish
              model={section?.dishes || []}
              onDelete={(dish) => {
                if (window.confirm('Vuoi rimuovere piatto ' + dish.name + ' dal menu ' + section?.name + '?')) {
                  setLoading(true);
                  sdk.removeMenuSectionDish({
                    id: (dish as any).pivot.id,
                  }).then(loadData);
                }
              }}
              addPlaceholder="Add dish"
              title="Dishes"
              generateListLink={(model: any) => {
                return `/menus/${params.menuId}/section/${params.sectionId}/dish/${model.id}`;
              }}
              autocomplete={{
                suggestions: filteredDishes.map(f => f.name),
                onTypeComplete: (query) => {
                  setFilteredDishes(
                    dishes.
                      filter(dish => section?.dishes?.filter(i => i.name === dish.name).length === 0).
                      filter(dish => dish.name.toLowerCase().includes(query.toLowerCase()))
                  );
                },
                onNewValue: async (value) => {
                  if (formRef && formRef.current) {
                    // console.log(formRef, formRef.current)
                    await (formRef.current as any).handleSubmit()
                  }
                  setLoading(true);
                  sdk.createDish({
                    name: value,
                    // price: 0,
                    // @ts-ignore
                    menu_section_id: section?.id
                  })
                    .then(((dish: any) => history.push('/menus/' + params.menuId + '/section/' + section?.id + '/dish/' + dish.results.id)))
                    .catch((e: any) => setErrors(Utils.formatErrors(e)))
                },
                onSuggestionSelect: async (value) => {
                  setLoading(true)
                  sdk.updateMenuSectionAddDish({
                    menu_section_id: section?.id as any,
                    dish_id: dishes.find(i => i.name === value)?.id as unknown as string,
                  }).then(loadData)
                    .catch((e: any) => setErrors(Utils.formatErrors(e)))
                },
              }}
              getOrder={(model) => {
                return (model as any).pivot.order;
              }}
              setOrder={(model, value) => {
                (model as any).pivot.order = value;
              }}
              onOrder={(model) => {
                setLoading(true);
                console.log('----', model);
                sdk.menuSectionDishSort(model.map(({id, pivot}: any) => ({
                  id: pivot.id,
                  order: pivot.order,
                })))
                  .then(loadData)
                  .catch(e => setErrors(Utils.formatErrors(e)))
                  .finally(() => setLoading(false));
              }}
            />
          </div>
        )
      }
    </div>
  );
}
