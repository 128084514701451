import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import { UpdateForm } from '../components/organisms/updateForm.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { Utils } from '../../../lib/utils/core';
import { useHistory, useParams } from 'react-router-dom';

export default function Table(props: any): JSX.Element {
  const params = useParams<{roomId: string, tableId: string}>()
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState<ResponseResults.Table>();
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getTable(Number(params.tableId))
      .then(res => {
        setTable(res.results);
        console.log(res.results)
      })
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, []);

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Locale</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            <div>
              <UpdateForm
                model={table}
                loading={loading}
                fields={[
                  {
                    name: 'name',
                    type: 'text',
                    placeholder: 'Nome del locale',
                  },
                  {
                    name: 'capacity',
                    type: 'number',
                    placeholder: 'Numeri di persone',
                  },
                ]}
                onSubmit={async (values: RequestFilters.TableUpdate) => {
                  setLoading(true);
                  sdk.updateTable({...values}).then(loadData)
                }}
              />
            </div>
          </div>
        )
      }
    </div>
  );
}
