import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Modal } from '../components/molecules/modal';
import { UpdateForm } from '../components/organisms/updateForm.component';
import { Utils } from '../../../lib/utils/core';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import Loader from '../components/atoms/loader';
import { ReservationForm } from '../components/organisms/reservationForm.component';
import CopyToClipboard from '../components/molecules/copy-to-clipboard';

const RadioButtons = ({ keyString, options, selectedValue, onChange }: any) => {
  const [value, setValue] = useState(selectedValue);

  const handleChange = (newValue: any) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className='d-inline-block'>
      {options.map((option: any) => (
        <button
          key={keyString + option.value}
          disabled={option.disabled}
          className={`mr-2 btn ${value === option.value ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => handleChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

type MultiSelectOption = {label: string, value: string}
const MultiSelect = (props: {
  options: MultiSelectOption[],
  onChange: (values: string[]) => void;
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOptionClick = (option: {label: string, value: string}) => {
    let newSelectedValues = [...selectedValues];
    if (selectedValues.includes(option.value)) {
      newSelectedValues = newSelectedValues.filter((value) => value !== option.value);
    } else {
      newSelectedValues.push(option.value);
    }
    setSelectedValues(newSelectedValues);
    props.onChange(newSelectedValues);
  };

  const filteredOptions = props.options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const closeDropdown = useCallback(() => {
    setShowDropdown(false);
    setSearchTerm('');
  }, [showDropdown])

  const toggleDropdown = useCallback(() => {
    if (showDropdown) {
      closeDropdown();
    } else {
      setShowDropdown(true);
    }
  }, [showDropdown])

  const handleClickOutOfDropdown = (e: any) => {
    if (e.target.closest('.multiselect') == null) {
      closeDropdown();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutOfDropdown);

    return () => {
      window.removeEventListener('click', handleClickOutOfDropdown);
    }
  }, [])

  return (
    <div className="position-relative multiselect" style={{zIndex: 9999}}>
      <div className="form-outline position-relative">
        <input
          className="form-control bg-white"
          type="text"
          readOnly={true}
          placeholder="Seleziona allergie"
          onClick={() => toggleDropdown()}
          value={props.options.filter((o) => selectedValues.includes(o.value)).map(o => o.label).join(', ')}
        />
        <span className="select-arrow position-absolute" style={{
          width: '0',
          height: '0',
          border: '4px solid transparent',
          borderTop: '4px solid',
          borderRight: '4px solid',
          margin: '15px',
          zIndex: 99,
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          transform: 'rotate(135deg)'
        }}></span>
      </div>
      {showDropdown && (
        <>
          <input type="text" placeholder="Cerca..." className="form-control" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />
          <div className="position-absolute overflow-auto" style={{ top: "calc(100% + 10px)", left: 0, right: 0, maxHeight: '150px' }}>
            <ul className="list-group">
              {filteredOptions.map((option, index) => (
                <li key={`multiselect-${index}`} className="list-group-item d-flex justify-content-between align-items-center" onClick={() => handleOptionClick(option)}>
                  {option.label}
                  {selectedValues.includes(option.value) && <span className="badge badge-primary badge-pill">Selezionato</span>}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

const today = (new Date()).toISOString().split('T')[0];

export default function Reservations(props: any): JSX.Element {
  const reservationDuration = 120; // TODO: read default reservation duration from local settings

  const [loading, setLoading] = useState(true);
  const [reservations, setReservations] = useState<ResponseResults.Reservation[]>([]);
  const [searchGuest, setSearchGuest] = useState('');
  const [date, setDate] = useState(today);
  const [rooms, setRooms] = useState<ResponseResults.Room[]>([]);
  const [times, setTimes] = useState<ResponseResults.ReservationsAvailabilities[]>();
  const [customers, setCustomers] = useState<ResponseResults.Customer[]>([]);
  const [allergens, setAllergens] = useState<ResponseResults.Allergen[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [filterState, setFilterState] = useState<string>('');
  const [openModal, setOpenModal] = useState<false | string>(false);
  const formRef = useRef();

  const states = ['pending', 'confirmed', 'arrived', 'cancelled'].map(s => ({value: s, label: s}));

  const [selectedTime, setSelectedTime] = useState(null);
  const handleSelectedTimeChange = (value: any) => {
    setSelectedTime(value);
  };

  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const handleSelectedAllergiesChange = (values: any) => {
    setSelectedAllergies(values)
  };

  const [reservationViewDate, setReservationViewDate] = useState(today);
  useEffect(() => {
    loadData();
  }, [reservationViewDate])

  const refreshReservations = useCallback(() => {
    sdk.getReservations(reservationViewDate).then(res => {
      setReservations(res.results);
      setLoading(false);
    });
  }, [reservationViewDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshReservations()
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [refreshReservations])


  const loadData = useCallback(() => {
    setLoading(true);
    Promise.all([
      sdk.getReservations(reservationViewDate).then(res => {
        setReservations(res.results);
      }),

      sdk.getAllergens().then((a) => {
        setAllergens(a.results)
      }),

      sdk.getCustomers().then(res => {
        setCustomers(res.results)
      }),
    ])
      .then(r => {
        setOpenModal(false)
      })
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [reservationViewDate])

  useEffect(() => {
    loadData()
  }, []);

  return (
    <div className="container-fluid">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <>

            <div className="clearfix">
              <div className='float-right mb-3'>
                <button className='btn btn-info mr-2' onClick={() => setOpenModal('walk-in')}>
                  <i className="fas fa-plus mr-2"></i>
                  Non prenotato
                </button>
                <button className='btn btn-primary' onClick={() => setOpenModal('new')}>
                  <i className="fas fa-plus mr-2"></i>
                  Prenotazione
                </button>
              </div>

              <div className='float-left mb-3'>
                <button className='btn btn-info mr-2' title="Aggiorna" onClick={() => {
                  setLoading(true);
                  refreshReservations();
                }}>
                  <i className="fas fa-undo"></i>
                </button>

                <input className="rounded align-bottom mr-3 p-1" type="date" value={reservationViewDate} onChange={({target: { value }}) => {
                  setReservationViewDate(value)
                }} />

                <button
                  className={`mr-2 btn ${filterState === '' ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => setFilterState('')}
                >
                  All
                </button>
                <RadioButtons
                  keyString="states"
                  options={states}
                  onChange={(state: any) => {
                    setFilterState(state)
                  }}
                />
              </div>
            </div>

            <div>
              <span className='d-block ml-auto' style={{width: 'fit-content'}}>
                <input
                  type="text"
                  placeholder="Cerca..."
                  className="form-control"
                  value={searchGuest}
                  onChange={(event) => setSearchGuest(event.target.value)}
                />
              </span>
            </div>

            <div className="overflow-auto">
              <table className="table table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Data</th>
                    <th scope="col">Nominativo</th>
                    <th scope="col">Ospiti</th>
                    <th scope="col">Orario</th>
                    <th scope="col">Tavoli</th>
                    <th scope="col">Allergie</th>
                    <th scope="col">Note</th>
                    <th scope="col">Stato</th>
                    <th scope="col">Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {reservations
                    .sort((a,b) => (a.date >= b.date ? 1 : -1) || a.slots[0].start - b.slots[0].start)
                    .filter(d => filterState === '' || d.state === filterState)
                    .filter(({customer}) => searchGuest === '' || `${customer.first_name} ${customer.last_name}`.toLowerCase().includes(searchGuest.toLowerCase()))
                    .map((data) => ({
                      ...data,
                      date: data.date,
                      nominative: data.customer ? (data.customer.first_name + ' ' +data.customer.last_name) : 'Ospite',
                      start: data.slots[0].start,
                      end: data.slots[data.slots.length - 1].end,
                      allergies: data.allergies,
                      tables: data.tables,
                    }))
                    .map((data, i) => (
                    <tr key={`reservation-${i}`} className={`${data.was_reserved_in_overbooking ? 'table-warning' : ''} ${data.state === 'cancelled' ? 'row-cancelled' : ''}`}>
                      <td>
                        {{
                          'walk-in': <i title='walk-in' className="fas fa-walking"></i>,
                          'phone': <i title='phone' className="fas fa-phone"></i>,
                          'website': <i title='website' className="fas fa-globe"></i>,
                          'thefork': <i title='The Fork' className="fas fa-utensils"></i>,
                        }[data.provenance]}
                      </td>
                      <td>{data.date.split('-').reverse().join('-')}</td>
                      <td>
                        <a>
                          {data.customer && <CopyToClipboard title="copy phone" data={data.customer.phone} />}
                          <span title={data.customer ? data.customer.phone : undefined} className='ml-2'>
                            {data.nominative}
                          </span>
                        </a>
                      </td>
                      <td>{data.guests}</td>
                      <td>{Utils.timeFormatterMtHM(data.start)}-{Utils.timeFormatterMtHM(data.end)}</td>
                      <td>{data.tables.map(a => a.name).join(', ')}</td>
                      <td>{data.allergies.map(a => a.name).join(', ')}</td>
                      <td>{data.was_reserved_in_overbooking ? (<>(prenotato in overbooking)<br /></>) : ''}{data.notes}</td>
                      <td>{data.state}</td>
                      <td>
                        {/* <button className="mr-2 btn btn-primary">
                          <i className="far fa-eye mr-2"></i>
                          Visualizza
                        </button> */}
                        {(data.state === 'pending' || data.state === 'confirmed') && (
                          <button
                            className="mr-2 btn btn-secondary"
                            onClick={() => {
                              setOpenModal('edit-' + data.id)
                            }}
                          >
                            <i className="fas fa-edit"></i>
                          </button>
                        )}
                        {data.state === 'pending' && (
                          <button
                            className="mr-2 btn btn-secondary"
                            onClick={() => {
                              if (window.confirm(`Vuoi accettare la prenotazione di ${data.nominative} di giorno ${data.date} alle ore ${Utils.timeFormatterMtHM(data.start)}?`)) {
                                setLoading(true);
                                sdk.updateReservationSetConfirmed(data.id).then(loadData);
                              }
                            }}
                          >
                            <i className="fas fa-check"></i>
                          </button>
                        )}
                        {/* Confermato ma senza tavoli assegnati */}
                        {(data.state === 'confirmed' && (!data.tables || data.tables.length === 0)) && (
                          <button
                            className="mr-2 btn btn-success"
                            onClick={() => {
                              // console.log('~~~~~~~', data);
                              sdk.getTablesAvailabilities(data.date).then(res => {
                                const interested = res.results.filter(r => r.start == data.start);
                                const groupedByRooms = Utils.groupBy(interested, 'room_name');
                                const _rooms = Object.entries(groupedByRooms).map(([room_name, tables]) => ({ name: room_name, tables: (tables as any).map(({tid, table_name, consecutiveAvailableSlots}: any) => ({id: tid, name: table_name, capacity: consecutiveAvailableSlots[0].capacity})) })) as any;
                                setRooms(
                                  _rooms
                                )
                                setOpenModal('confirm-' + data.id)
                              })
                            }}
                          >
                            <i className="fas fa-chair"></i>
                          </button>
                        )}
                        {/* Confermato con tavoli assegnati */}
                        {(data.state === 'confirmed' && (data.tables && data.tables.length > 0)) && (
                          <>
                            <button
                              className="mr-2 btn btn-danger"
                              onClick={() => {
                                if (window.confirm(`Vuoi liberare i tavoli (${data.tables.map(t => t.name).join(', ')}) associati alla prenotazione di ${data.nominative}?`)) {
                                  setLoading(true);
                                  sdk.updateReservationRemoveTable({ reservation_id: data.id }).then(loadData)
                                    .catch(e => setErrors(Utils.formatErrors(e)))
                                    .finally(() => setLoading(false))
                                }
                              }}
                            >
                              <i className="fas fa-chair"></i>
                            </button>
                            <button
                              className="mr-2 btn btn-success"
                              onClick={() => {
                                if (window.confirm(`${data.nominative} è arrivato?`)) {
                                  setLoading(true);
                                  sdk.updateReservationSetArrived(data.id).then(loadData);
                                }
                              }}
                            >
                              <i className="fas fa-sign-in-alt"></i>
                            </button>
                          </>
                        )}
                        {/* pending o confermato senza tavoli */}
                        {(data.state === 'pending' || (data.state === 'confirmed' && (!data.tables || data.tables.length === 0))) && (
                          <button
                            className="mr-2 btn btn-danger"
                            onClick={() => {
                              if (window.confirm(`Vuoi cancellare la prenotazione di ${data.nominative} di giorno ${data.date} alle ore ${Utils.timeFormatterMtHM(data.start)}?`)) {
                                setLoading(true)
                                sdk.updateReservationSetCancelled(data.id).then(loadData);
                              }
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        )}
                        {(data.state === 'arrived') && (
                          <button
                            className="mr-2 btn btn-danger"
                            onClick={() => {
                              if (window.confirm(`Vuoi impostare che il cliente non è ancora arrivato?`)) {
                                setLoading(true)
                                sdk.updateReservationSetConfirmed(data.id).then(loadData);
                              }
                            }}
                          >
                            <i className="fas fa-undo mr-2"></i>
                            Non arrivato
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {openModal && (
              <Modal
                size="large"
                onClose={() => setOpenModal(false)}
              >
                {openModal.startsWith('edit') && (() => {
                  const reservationId = openModal.substr(openModal.indexOf('-') + 1);
                  const reservation = reservations.find(({id}) => id === reservationId);

                  return (
                    <>
                      <ReservationForm
                        type="update"
                        initialValues={{
                          reservation: reservation,

                          first_name: reservation?.customer?.first_name,
                          last_name: reservation?.customer?.last_name,
                          email: reservation?.customer?.email,
                          phone: reservation?.customer?.phone,
                          customer_id: reservation?.customer_id,
                          prefered_language: reservation?.customer?.prefered_language,

                          guests: reservation?.guests,
                          notes: reservation?.notes || '',

                          date: reservation?.date,
                          start: reservation?.slots[0].start,

                          allergies: reservation?.allergies.map(({id}) => id),

                          id: reservation?.id,
                          provenance: reservation?.provenance,
                          external_id: reservation?.external_id,
                        }}
                        customers={customers}
                        allergens={allergens}
                        onSubmit={loadData}
                      />
                    </>
                  );
                })()}
                {openModal.startsWith('confirm') && (
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const reservationId = openModal.substr(openModal.indexOf('-') + 1);

                    if ((e.target as any).table == undefined) {
                      alert('Seleziona un tavolo');
                      return;
                    }

                    const tables = (typeof (e.target as any).table[Symbol.iterator] === 'function') ? [...(e.target as any).table] : [(e.target as any).table];
                    const selectedTablesId = tables.filter((el: any) => el.checked).map(({value}: any) => value);

                    if (selectedTablesId.length > 0) {
                      setLoading(true);
                      sdk.updateReservationAddTable({ reservation_id: reservationId, table_ids: selectedTablesId }).then(loadData);
                      setOpenModal(false);
                    } else {
                      alert('Seleziona un tavolo');
                    }
                  }}>
                    {rooms.length === 0 && (
                      <h3>Non ci sono tavoli disponibili</h3>
                    )}
                    {rooms.map((room, i) => (
                      <div key={`room-${i}`}>
                        <label>
                          {room.name}
                        </label>
                        <div style={{columnCount: 3}}>
                          {room.tables
                            .sort((a,b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
                            .map((table, i) => (
                              <div key={`table-${i}`}>
                                <label>
                                  <input type='checkbox' name="table" value={table.id} />
                                  {table.name} ({table.capacity})
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                    <button className='btn btn-primary btn-block' type='submit'>Assegna</button>
                  </form>
                )}

                {(openModal == 'new' || openModal == 'walk-in') && (
                  <div>
                    <ReservationForm
                      type={openModal == 'new' ? 'create' : 'walk-in'}
                      initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        customer_id: 0,

                        guests: 2,
                        notes: '',

                        date: today,
                        start: undefined as any,

                        allergies: [],
                      }}
                      customers={customers}
                      allergens={allergens}
                      onSubmit={loadData}
                    />
                  </div>
                )}
              </Modal>
            )}
          </>
        )}

    </div>
  )
}
