import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import sdk from '../../../app-lib/sdk/sdk.service';
import { Utils } from '../../../lib/utils/core';
import Loader from '../components/atoms/loader';
import List, { ListArgs } from '../components/organisms/list.component';
import { UpdateForm } from '../components/organisms/updateForm.component';

export default function Menu(props: any): JSX.Element {
  const [pageLoaded, setPageLoaded] = useState(false);

  const [menu, setMenu] = useState<ResponseResults.Menu>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<false | string[]>(false);
  const formRef = useRef();

  // const [sections, setSections] = useState<ResponseResults.MenuSection[]>();

  // TODO: creare custom hook
  const menuId = Number(useParams<any>().menuId);
  const history = useHistory();

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getMenu(menuId)
      .then(res => {
        setMenu(res.results);
      })
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => {
        setPageLoaded(true)
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData()
  }, []);

  const ListSection = List as React.FC<ListArgs<ResponseResults.MenuSection>>;

  return (
    <div className="container">
      <h1 className="text-center">Menù</h1>

      {!pageLoaded || loading || !menu
        ? (
          <div className="text-center">
            <Loader />
          </div>
        )
        : (
          <div className="col-12 col-md-6 offset-md-3">
            <UpdateForm
              formRef={formRef}
              model={menu}
              loading={loading}
              fields={[
                {
                  name: 'name',
                  type: 'text',
                  placeholder: 'Enter Menu name...',
                },
                {
                  name: 'is_active',
                  type: 'switch',
                  placeholder: 'Is active',
                },
                {
                  name: 'description',
                  type: 'text',
                  placeholder: 'Enter Menu description...',
                },
                {
                  name: 'image',
                  type: 'image',
                  placeholder: 'Add image...',
                },
              ]}
              onSubmit={(values: RequestFilters.MenuUpdate) => {
                setLoading(true);
                sdk.updateMenus(values)
                  .then(loadData)
                  .catch(e => {setErrors(Utils.formatErrors(e)); setLoading(false)})
              }}
            />

            <button className="btn btn-danger btn-block mt-2" onClick={() => {
              if (window.confirm('Vuoi eliminare definitivamente il menu ' + menu?.name + '?')) {
                setLoading(true);
                sdk.deleteMenu((menu as ResponseResults.Menu).id)
                  .then(() => {
                    history.replace(
                      history.location.pathname.substring(0, history.location.pathname.indexOf('/menu/'))
                    );
                  })
                  .catch(e => setErrors(Utils.formatErrors(e)))
                  .finally(() => setLoading(false))
              }
            }}>Delete</button>

            <ListSection
              model={menu.sections || []}
              title="Menu sections"
              addPlaceholder="Add section"
              generateListLink={(model: any) => {
                return `/menus/${menuId}/section/${model.id}`;
              }}
              onCreateNew={async (value: string) => {
                if (formRef && formRef.current) {
                  // console.log(formRef, formRef.current)
                  await (formRef.current as any).handleSubmit()
                }
                setLoading(true);
                sdk.createMenuSection({
                  name: value,
                  is_active: true,
                  menu_id: menu.id
                })
                  .then((section => history.push('/menus/' + menuId + '/section/' + section.results.id)))
                  .catch(e => setErrors(Utils.formatErrors(e)))
              }}
              getOrder={(model) => {
                return model.order
              }}
              setOrder={(model, value) => {
                model.order = value
              }}
              onOrder={(model) => {
                setLoading(true);
                sdk.menuSectionSort(model)
                  .then(loadData)
                  .catch(e => setErrors(Utils.formatErrors(e)))
                  .finally(() => setLoading(false));
              }}
            />

            {/* {errors &&
              errors.map(
                (el: string, key: number): JSX.Element => (
                  <p className="text-danger mt-2 mb-0" key={'error-' + key}>
                    {el}
                  </p>
                ),
              )
            } */}
          </div>
        )
      }
    </div>
  );
}
