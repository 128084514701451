import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import List, { ListArgs } from '../components/organisms/list.component';
import { Utils } from '../../../lib/utils/core';

export default function Menus(props: any): JSX.Element {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState<ResponseResults.Menu[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getMenus()
      .then(res => setMenus(res.results))
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData();
  }, []);

  const ListMenu = List as React.FC<ListArgs<ResponseResults.Menu>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Menùs</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-6 offset-md-3">
            <ListMenu
              model={menus}
              title=""
              addPlaceholder="Add menu"
              generateListLink={(model: any) => {
                return `/menus/${model.id}`;
              }}
              onCreateNew={(value: string) => {
                setLoading(true);
                sdk.createMenus({
                  name: value,
                  is_active: true,
                })
                  .then((menu => history.push('/menus/' + menu.results.id)))
                  .catch(e => setErrors(Utils.formatErrors(e)))
              }}
              getOrder={(model) => {
                return model.order
              }}
              setOrder={(model, value) => {
                model.order = value
              }}
              onOrder={(model) => {
                setLoading(true);
                sdk.menusSort(model)
                  .then(loadData)
                  .catch(e => setErrors(Utils.formatErrors(e)))
                  .finally(() => setLoading(false));
              }}
            />
          </div>
        )
      }
    </div>
  );
}
