import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loader from '../components/atoms/loader';
import { Link, useHistory, useParams } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import Select from 'react-select';

import List, { ListArgs } from '../components/organisms/list.component';
import { UpdateForm } from '../components/organisms/updateForm.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import Autocomplete from '../components/atoms/autocomplete';
import { Modal } from '../components/molecules/modal';
import { Utils } from '../../../lib/utils/core';

function SelectOrCreate({options, onAddValue, onNewValue, defaultOption}: any) {
  const [customValue, setCustomValue] = useState('');

  const handleOptionChange = (selectedOption: any) => {
    if (options.includes(selectedOption)) {
      onAddValue(selectedOption);
    } else {
      onNewValue(selectedOption.label.split('+ ')[1])
    }
  };

  const handleCustomValueChange = (value: any) => {
    setCustomValue(value);
  };

  const highlightSearchText = (label: any, input: any) => {
    const parts = label.split(new RegExp(`(${input})`, 'gi'));
    return (
      <span>
        {parts.map((part: any, index: number) =>
          part.toLowerCase() === input.toLowerCase() ? (
            <strong key={index}>{part}</strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };
  const customOptionRenderer = ({ label }: any) => {
    return highlightSearchText(label || '', customValue);
  };

  const customOption = (customValue.length > 0 && !options.map((o: any) => o.label).includes(customValue)) ? [{ label: `+ ${customValue}`, value: customValue }, ...options] : options;

  return (
    <Select
      options={customOption}
      value={defaultOption}
      onInputChange={handleCustomValueChange}
      onChange={handleOptionChange}
      isSearchable
      formatOptionLabel={customOptionRenderer}
    />
  );
}

export default function Dish(props: any): JSX.Element {
  const history = useHistory();

  const params = useParams<{menuId: string, sectionId: string, dishId: string}>()

  const [loading, setLoading] = useState(true);
  const [dish, setDish] = useState<ResponseResults.Dish>();
  const [ingredients, setIngredients] = useState<ResponseResults.Ingredient[]>([]);
  const [filteredIngredients, setFilteredIngredients] = useState<ResponseResults.Ingredient[]>([]);
  const [tags, setTags] = useState<ResponseResults.Tag[]>([]);
  const [allergens, setAllergens] = useState<ResponseResults.Allergen[]>([]);
  const [provenances, setProvenances] = useState<ResponseResults.Provenance[]>([]);
  const [filteredTags, setFilteredTags] = useState<ResponseResults.Tag[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<false | string>(false);
  const formRef = useRef();

  const isAssociated = useMemo(() => dish?.menu_sections && dish?.menu_sections.length > 0, [dish]);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getMenuSectionDish(Number(params.dishId))
      .then(res => {
        setDish(res.results)
        sdk.getIngredients()
          .then(res => setIngredients(res.results))
          .catch(e => setErrors(Utils.formatErrors(e)))
          .finally(() => setLoading(false))
        sdk.getTags()
          .then(res => setTags(res.results))
          .catch(e => setErrors(Utils.formatErrors(e)))
          .finally(() => setLoading(false))
        sdk.getAllergens()
          .then(res => setAllergens(res.results))
          .catch(e => setErrors(Utils.formatErrors(e)))
          .finally(() => setLoading(false))
        sdk.getProvenances()
          .then(res => setProvenances(res.results))
          .catch(e => setErrors(Utils.formatErrors(e)))
          .finally(() => setLoading(false))
      })
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, []);

  // const ListIngredient = List as React.FC<ListArgs<ResponseResults.Ingredient>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Piatto</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className='row'>
            <div className="col-12 col-md-6 offset-md-3">
              {isAssociated && (
                <div className="alert alert-warning" role="alert">
                  <p>
                    Attenzione! Modificando qui questo piatto, lo modificherai anche nelle seguenti sezioni:
                  </p>
                  <ul>
                    {dish?.menu_sections?.
                      // .flatMap(d => d.menu?.
                      map(ms => ({
                        label: `${ms.name} (${ms.menu?.name})`,
                        url: `/menus/${ms.menu?.id}/section/${ms.id}`,
                      })).
                      map(({ label, url }: any, idx: number) => (
                        <li key={`related-${idx}`}>
                          <Link to={url}>
                            <span>{label}</span>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>

            <div className='col-12 col-md-6'>
              <UpdateForm
                formRef={formRef}
                model={dish}
                loading={loading}
                fields={[
                  // {
                  //   name: 'is_active',
                  //   type: 'switch',
                  //   placeholder: 'Is active',
                  // },
                  {
                    name: 'name',
                    type: 'text',
                    placeholder: 'Enter dish name...',
                  },
                  {
                    name: 'price_default',
                    type: 'price',
                    placeholder: 'Enter dish price...',
                  },
                  {
                    name: 'description',
                    type: 'text',
                    placeholder: 'Enter dish description...',
                  },
                  {
                    name: 'image',
                    type: 'image',
                    placeholder: 'Add image...',
                  },
                ]}
                onSubmit={(values: RequestFilters.DishUpdate) => {
                  setLoading(true);
                  sdk.updateDish({...values, price_default: values.price_default * 100})
                    .then(loadData)
                    .catch(e => {setErrors(Utils.formatErrors(e)); setLoading(false)})
                }}
              />

              <button className="btn btn-danger btn-block mt-2" disabled={isAssociated} title={isAssociated ? 'Non puoi cancellare un piatto associato a dei menu' : ''} onClick={() => {
                if (window.confirm('Vuoi eliminare definitivamente il piatto ' + dish?.name + '?')) {
                  setLoading(true);
                  sdk.deleteDish((dish as ResponseResults.Dish).id)
                    .then(() => {
                      if (history.location.pathname.split('/')[1] === 'dishes') {
                        history.replace('/dishes');
                      } else {
                        history.replace(
                          history.location.pathname.substring(0, history.location.pathname.indexOf('/dish/'))
                        );
                      }
                    })
                    .catch(e => setErrors(Utils.formatErrors(e)))
                    .finally(() => setLoading(false))
                }
              }}>Delete</button>
            </div>
            <div className='col-12 col-md-6'>
              <div>
                <p className="paragraph mb-0 text-dark text-uppercase font-weight-bold">
                  Tags
                </p>
                <div>
                  {dish?.tags?.map((i, k) => (
                    <React.Fragment key={"dish-tag-" + k}>
                      <span
                        onClick={() => {
                          if (window.confirm('Vuoi rimuovere il tag ' + i.name + '?')) {
                            setLoading(true);
                            sdk.removeTag({
                              dish_id: dish.id,
                              tag_id: i.id,
                            }).then(loadData);
                          }
                        }}
                      >{i.name}</span>
                      {k + 1 !== dish.tags?.length ? ', ' : ''}
                    </React.Fragment>))}
                </div>
                <Autocomplete
                  suggestions={filteredTags.map(fi => fi.name)}
                  onTypeComplete={(query: string) => {
                    setFilteredTags(
                      tags
                        // @ts-ignore
                        .filter(tag => dish.tags.filter(i => i.name === tag.name).length === 0)
                        .filter(tag => tag.name.toLowerCase().includes(query.toLowerCase()))
                    )
                  }}
                  onNewValue={async (value) => {
                    // TODO: aprire modal con richiesta dati
                    if (tags.filter(tag => tag.name === value).length === 0) {
                      if (formRef && formRef.current) {
                        // console.log(formRef, formRef.current)
                        await (formRef.current as any).handleSubmit()
                      }
                      setLoading(true);
                      sdk.createTag({
                        name: value,
                        dish_id: dish?.id,
                      }).then(loadData)
                    }
                  }}
                  onSuggestionSelect={async (value) => {
                    if (formRef && formRef.current) {
                      // console.log(formRef, formRef.current)
                      await (formRef.current as any).handleSubmit()
                    }
                    setLoading(true);
                    sdk.updateDishAddTag({
                      dish_id: dish?.id as any,
                      tag_id: tags.find(i => i.name === value)?.id as unknown as string,
                    }).then(loadData)
                  }}
                />
              </div>

              <div className="mt-3">
                <p className="paragraph mt-3 mb-0 text-dark text-uppercase font-weight-bold">
                  Ingredients
                </p>
                <div>
                  {dish?.ingredients?.map((i, k) => (
                    <React.Fragment key={"dish-ingredient-" + k}>
                      <span
                        onClick={() => {
                          if (window.confirm('Vuoi rimuovere l\'ingrediente ' + i.name + '?')) {
                            setLoading(true);
                            sdk.removeIngredient({
                              dish_id: dish.id,
                              ingredient_id: i.id,
                            }).then(loadData);
                          }
                        }}
                      >{i.name}</span>
                      {k + 1 !== dish.ingredients?.length ? ', ' : ''}
                    </React.Fragment>))}
                </div>
                <Autocomplete
                  suggestions={filteredIngredients.map(fi => fi.name)}
                  onTypeComplete={(query: string) => {
                    setFilteredIngredients(
                      ingredients
                        // @ts-ignore
                        .filter(ingredient => dish.ingredients.filter(i => i.name === ingredient.name).length === 0)
                        .filter(ingredient => ingredient.name.toLowerCase().includes(query.toLowerCase()))
                    )
                  }}
                  onNewValue={(value) => {
                    // TODO: aprire modal con richiesta dati
                    if (ingredients.filter(ingr => ingr.name === value).length === 0) {
                      setOpenModal(value);
                    }
                  }}
                  onSuggestionSelect={async (value) => {
                    if (formRef && formRef.current) {
                      // console.log(formRef, formRef.current)
                      await (formRef.current as any).handleSubmit()
                    }
                    setLoading(true);
                    sdk.updateDishAddIngredient({
                      dish_id: dish?.id as any,
                      ingredient_id: ingredients.find(i => i.name === value)?.id as unknown as string,
                    }).then(loadData)
                      .catch((e: any) => setErrors(Utils.formatErrors(e)))
                  }}
                />
              </div>

              <div className="mt-3">
                <p className="paragraph mt-3 mb-0 text-dark text-uppercase font-weight-bold">
                  Provenance
                  {dish?.provenance && (
                    <button
                      className="ml-2 py-1 px-2 border-0 bg-transparent text-danger"
                      onClick={() => {
                        if (window.confirm(`Vuoi rimuovere la provenienza da questo piatto?`)) {
                          setLoading(true)
                          // sdk.updateReservationSetCancelled(data.id).then(loadData);
                          sdk.removeProvenanceFromDish(dish.id).then(loadData);
                        }
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  )}
                </p>
                <SelectOrCreate
                  defaultOption={{label: dish?.provenance?.name, value: dish?.provenance?.id}}
                  options={provenances.map(p => ({label: p.name, value: p.id}))}
                  onNewValue={async (value: any) => {
                    if (dish?.provenance?.name !== value) {
                      if (formRef && formRef.current) {
                        // console.log(formRef, formRef.current)
                        await (formRef.current as any).handleSubmit()
                      }
                      setLoading(true);
                      sdk.createProvenance({
                        name: value,
                        dish_id: dish?.id,
                      }).then(loadData)
                    }
                  }}
                  onAddValue={async (selectedOption: any) => {
                    if (formRef && formRef.current) {
                      // console.log(formRef, formRef.current)
                      await (formRef.current as any).handleSubmit()
                    }
                    setLoading(true);
                    sdk.updateDishAddProvenance({
                      dish_id: dish?.id as any,
                      provenance_id: selectedOption.value,
                    }).then(loadData)
                  }}
                />
                {/* <Autocomplete
                  suggestions={filteredProvenances.map(fi => fi.name)}
                  onTypeComplete={(query: string) => {
                    setFilteredProvenances(
                      provenances
                        .filter(provenance => provenance.name.toLowerCase().includes(query.toLowerCase()))
                    )
                  }}
                  onNewValue={(value) => {
                    console.log('create', value, 'and associate to this dish')
                    // TODO: aprire modal con richiesta dati
                    // if (ingredients.filter(ingr => ingr.name === value).length === 0) {
                    //   setOpenModal(value);
                    // }
                  }}
                  onSuggestionSelect={async (value) => {
                    console.log('associate', value, 'to this dish')

                    // if (formRef && formRef.current) {
                    //   // console.log(formRef, formRef.current)
                    //   await (formRef.current as any).handleSubmit()
                    // }
                    // setLoading(true);
                    // sdk.updateDishAddIngredient({
                    //   dish_id: dish?.id as any,
                    //   ingredient_id: ingredients.find(i => i.name === value)?.id as unknown as string,
                    // }).then(loadData)
                    //   .catch((e: any) => setErrors(Utils.formatErrors(e)))
                  }}
                /> */}
              </div>

              {openModal && (
                <Modal
                  onClose={() => setOpenModal(false)}
                >
                  <div className="tmp-form-ingredients">
                    <UpdateForm
                      model={{
                        name: openModal,
                      }}
                      loading={loading}
                      fields={[
                        {
                          name: '',
                          type: 'label',
                          placeholder: 'Nome ingrediente',
                        },
                        {
                          name: 'name',
                          type: 'text',
                          placeholder: 'Enter menu section name...',
                        },
                        {
                          name: '',
                          type: 'label',
                          placeholder: 'Tipologia',
                        },
                        {
                          name: 'vegan',
                          type: 'switch',
                          placeholder: 'vegan',
                        },
                        {
                          name: 'bio',
                          type: 'switch',
                          placeholder: 'bio',
                        },
                        {
                          name: 'frozen',
                          type: 'switch',
                          placeholder: 'frozen',
                        },
                        {
                          name: 'vegetarian',
                          type: 'switch',
                          placeholder: 'vegetarian',
                        },
                        {
                          name: '',
                          type: 'label',
                          placeholder: 'Allergeni',
                        },
                        ...allergens.map(a => ({
                          name: `allergens.${a.name}`,
                          type: 'switch',
                          placeholder: a.name,
                        })),
                      ]}
                      onSubmit={async (values: RequestFilters.IngredientCreate) => {
                        setLoading(true);
                        setOpenModal(false);
                        if (formRef && formRef.current) {
                          // console.log(formRef, formRef.current)
                          await (formRef.current as any).handleSubmit()
                        }
                        sdk.createIngredient({
                          ...values,
                          // name: value,
                          price_default: 0,
                          price_sale: 0,
                          //. vegan: false,
                          notes: '',
                          //. bio: false,
                          //. frozen: false,
                          //. vegetarian: false,
                          // @ts-ignore
                          dish_id: dish.id,
                        }).then(loadData)
                      }}
                    />
                  </div>
                </Modal>
              )}

              {/* <ListIngredient
                model={dish?.ingredients || []}
                title="Ingredients"
                addPlaceholder="Add ingredient"
                generateListLink={(model: any) => {
                  return `/menus/${params.menuId}/section/${params.sectionId}/dish/${model.id}`;
                }}
                onCreateNew={(value: string) => {
                  sdk.createIngredient({
                    name: value,
                    price: 0,
                    // @ts-ignore
                    // menu_sections: sections?.id
                  })
                    .then((res: any) => console.log(res))
                    .catch((e: any) => setErrors(Utils.formatError(e)))
                }}
              /> */}
            </div>
          </div>
        )
      }
    </div>
  );
}
