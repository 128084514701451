import React from 'react';

export const Modal = ({children, onClose, size}: any) => {
  return (

    <div className="modal" role="dialog" style={{display: 'block', backgroundColor: 'rgba(0,0,0,.6)'}}>
      <div className={`modal-dialog ${size === 'large' ? 'modal-lg' : ''}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title">Modal title</h5> */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {children}
            {/* <p>Modal body text goes here.</p> */}
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-primary">Save changes</button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
