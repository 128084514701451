import React, { useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import { Utils } from '../../../lib/utils/core';
import CopyToClipboard from '../components/molecules/copy-to-clipboard';

export default function Customers(props: any): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<ResponseResults.Customer[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    sdk.getCustomers()
      .then(res => setCustomers(res.results))
      .catch(e => setErrors(Utils.formatErrors(e)))
      .finally(() => setLoading(false))
  }, []);

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error, idx) => (
            <p key={'error-' + idx}>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Customers</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 col-md-10 offset-md-1 overflow-auto">
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Cognome</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefono</th>
                  <th>Numero prenotazioni</th>
                </tr>
              </thead>
              <tbody>
                {customers.sort((c1, c2) => `${c1.last_name} ${c1.first_name}` > `${c2.last_name} ${c2.first_name}` ? 1 : -1).map(customer => (
                  <tr key={`customer-${customer.id}`}>
                    <td>{customer.last_name}</td>
                    <td>{customer.first_name}</td>
                    <td>{customer.email}</td>
                    <td>
                      <CopyToClipboard data={customer.phone} />
                      {' '}{customer.phone}
                    </td>
                    <td>{customer.reservations?.filter(r => r.state !== 'cancelled').length}</td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        )
      }
    </div>
  );
}
