import React, { useEffect, useState } from "react";

export default function CopyToClipboard({ data, title = '' }: {data: string, title?: string}) {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      navigator.clipboard.writeText(data);
      setTimeout(() => {
        setCopied(false)
      }, 1500);
    }
  }, [isCopied])

  return (
    <i
      role='button'
      title={title}
      className={`far fa-clipboard p-2 ${isCopied ? 'text-success' : ''}`}
      style={{transition: 'ease all .3s'}}
      onClick={() => setCopied(true)}
    />
  );
}
